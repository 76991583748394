import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import {
  createRecoCohort,
  updateRecoCohort,
  getDropdownValuesForCohortRecommendation,
  getFilterValues,
  getRadiologistForDropdown,
  getLocations,
} from "../../../actions/radminUploads";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { capitalize } from "lodash";
import { CalendarIcon } from "../../../components/Dialog/Icon";
import { TextField, InputLabel, makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import Box from "../../../components/_styles/Box";

import DatePicker from "react-datepicker";
import moment from "moment";
import {
  DateContainer,
  DatePickerButton,
} from "../../../components/Dialog/EditFormCenter";
import { Card, PageTitle } from "../../../styles";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";
import RemoveConfirmation from "./RemoveConfirmation";

const Remove = styled.div`
  cursor: pointer;
`;

const IconContainer = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 20px;

  &:hover svg:not(.light) {
    display: none;
  }

  &:hover svg.light {
    display: block;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg.light {
    display: none;
  }
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  saveButton: {
    color: "#FFFFFF",
    backgroundColor: "#00AEEF",
    textTransform: "none",
    border: "2px solid transparent",
    width: "280px",
    "&:hover": {
      backgroundColor: "transparent",
      border: "2px solid #00AEEF",
      color: "#00AEEF",
    },
  },
  additionalSubDimensionsButton: {
    color: "#00AEEF",
    textTransform: "none",
    width: "250px",
    background: "transparent",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  additionalDimensionsButton: {
    color: "#00AEEF",
    textTransform: "none",
    width: "200px",
    background: "transparent",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  removeAllSubDimensionsButton: {
    color: "#CF3131",
    textTransform: "none",
    width: "200px",
    background: "transparent",
    border: "2px solid #CF3131",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  inputLabel: {
    marginTop: "35px",
    fontSize: "16px",
    textAlign: "left",
    color: "#3F4857",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      padding: "13.5px 14px",
    },
  },
  title: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      padding: "13.5px 14px",
    },
  },
  datePicker: {
    width: "100%",
    "& .react-datepicker": {
      fontSize: "1rem !important",
      top: "58px",
    },
  },
  valuePicker: {
    width: "25%",
  },
});

const Section = styled.div`
  display: flex;
  gap: 20px;
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CheckboxTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-align: left;
  text-transform: uppercase;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwitchContainer = styled.div`
  padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width: fit-content;
`;

const SwitchButton = styled.button`
  padding: 7px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  border: 0;
  outline: 0;
  border-radius: 6px;
`;

const operatorsArrayDropdownOnly = [
  "Contains",
  "Does not contain",
  "Is equal to",
  "Is not equal to",
];

const operatorsArrayBooleanOnly = ["Is equal to"];

const operatorsArrayText = [
  "Contains",
  "Does not contain",
  "Is equal to",
  "Is not equal to",
];

const operatorsArrayDate = [
  "Is equal to",
  "Is not equal to",
  "Is a timestamp after",
  "Is a timestamp before",
  "Is a timestamp greater than",
  "Is a timestamp less than",
];

const messageOnlyOperator = ["Is equal to", "Is not equal to"];
const constants = ["Dimensions", "Operators", "Values"];

const booleanValues = ["true", "false"];

const needsToBeBooleanValues = [
  "report__patient__notes__note_text__isnull",
  "conditional",
  "negated",
];
const needsToBeDateInputValues = [
  "nlp_range_start",
  "nlp_range_end",
  "report__initial_exam_date",
];
const needsToBeDropdownValues = [
  "modality",
  "anatomy",
  "status",
  "timeframe_status_extracted",
];

const needsToBeFixedDropdownValues = ["age_group"];

const SelectContainer = styled.div`
  width: 250px;
  //   margin-top: 35px;
  display: flex;
  flex-direction: column;
  div.select__value-container {
    height: 38px;
    cursor: pointer;
  }
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-align: left;
  text-transform: uppercase;
`;

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const RadioOption = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const Create = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [firstFilterId, setFirstFilterId] = useState("");
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const isLoading = useSelector(
    (state) => state.radAdmin.cohortsRecommendations.isLoading
  );
  const isEditClicked = props.location.state?.data;

  const radiologists = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownRadiologists.data
  );

  const fixedValues = useSelector(
    (state) => state.radAdmin.cohortsRecommendations.fixedValues.data
  );
  const getFixedValues = (attribute) => {
    let data = fixedValues.find((val) => val.attribute === attribute);
    if (!data) return [];
    return data.values;
  };
  const radiologistOptions = radiologists?.length
    ? radiologists
        .map((item) => ({
          value: item?.id,
          label: capitalize(
            item?.first_name?.replace(/_/g, " ") +
              " " +
              item?.last_name?.replace(/_/g, " ")
          ),
          category: "radiologist",
        }))
        .filter((item) => item.value)
    : [];
  const [cohortName, setCohortName] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [selectedOperator, setSelectedOperator] = useState(
    props.location.state?.data?.raw_filters?.condition === "or"
      ? { value: "or", label: "OR", category: "Operator" }
      : { value: "and", label: "AND", category: "Operator" }
  );
  const [mainRows, setMainRows] = useState([
    {
      subGroupCondition: "and",
      subGroups: [{ attribute: "", operator: "", value: "" }],
    },
  ]);

  const [filterOption, setFilterOptions] = useState([]);

  const [ageOptions, setAgeOptions] = useState([]);
  const [cohortFilters, setCohortFilters] = useState(
    props.location.state?.data?.raw_filters
  );

  function getDateDiff(date) {
    let today = new Date();

    let otherDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    otherDate.setHours(0, 0, 0, 0);

    let differenceInMs = otherDate - today;

    const differenceInDays = Math.abs(
      Math.round(differenceInMs / (1000 * 60 * 60 * 24))
    );

    return differenceInDays;
  }

  useEffect(() => {
    if (props.location.state?.data) {
      setCohortName( props.location.state?.data?.title)
      setIsChecked(props.location.state?.data?.bypass_validation)
      let filtersArray = cohortFilters?.filters;

      let arr = filtersArray.map((item) => {
        if (item.filters && item.condition) {
         
         
          return {
            subGroupCondition: item.condition,
            subGroups: item.filters.map((itm) => {
              if (
                needsToBeDropdownValues.includes(itm.attribute) &&
                isDropdownRequired(itm.attribute)
              ) {
                dispatch(getDropdownValuesForCohortRecommendation(itm.attribute));
              }
              return {
                operator:
                  itm.operator === "__gt"
                    ? {
                        label: "Is a timestamp after",
                        value: "Is a timestamp after",
                      }
                    : itm.operator === "__icontains" && itm.exclude === 0
                    ? {
                        label: "Contains",
                        value: "Contains",
                      }
                    : itm.operator === "__icontains" && itm.exclude === 1
                    ? {
                        label: "Does not contain",
                        value: "Does not contain",
                      }
                    : itm.operator === "__lt"
                    ? {
                        label: "Is a timestamp before",
                        value: "Is a timestamp before",
                      }
                    : itm.operator === "__gte"
                    ? {
                        label: "Is a timestamp greater than",
                        value: "Is a timestamp greater than",
                      }
                    : itm.operator === "__lte"
                    ? {
                        label: "Is a timestamp less than",
                        value: "Is a timestamp less than",
                      }
                    : itm.operator === "" && itm.exclude === 0
                    ? { label: "Is equal to", value: "Is equal to" }
                    : { label: "Is not equal to", value: "Is not equal to" },
                value:
                  itm.operator === "__gte"
                    ? getDateDiff(itm.value)
                    : itm.operator === "__lte"
                    ? getDateDiff(itm.value)
                    : itm.operator === "__gt" || itm.operator === "__lt"
                    ? new Date(itm.value)
                    : isDropdownRequired(itm.attribute)
                    ? { label: itm.value, value: itm.value }
                    : itm.attribute === "report__location_address"
                    ? { label: itm.value.replace(/\^/g, ","), value: itm.value }
                    : itm.attribute === "report__radiologist_id"
                    ? { label: itm.valueLabel, value: itm.value }
                    : itm.value,
                exclude: itm.exclude,
                attribute: {
                  label: itm.label,
                  value: itm.attribute,
                },
              };
            }),
          };
        } else {
          let { attribute, label } = item;

          if (
            needsToBeDropdownValues.includes(attribute) &&
            isDropdownRequired(attribute)
          ) {
            dispatch(getDropdownValuesForCohortRecommendation(attribute));
          }
          return {
            subGroupCondition: "and",
            subGroups: [
              {
                operator:
                  item.operator === "__gt"
                    ? {
                        label: "Is a timestamp after",
                        value: "Is a timestamp after",
                      }
                    : item.operator === "__icontains" && item.exclude === 0
                    ? {
                        label: "Contains",
                        value: "Contains",
                      }
                    : item.operator === "__icontains" && item.exclude === 1
                    ? {
                        label: "Does not contain",
                        value: "Does not contain",
                      }
                    : item.operator === "__lt"
                    ? {
                        label: "Is a timestamp before",
                        value: "Is a timestamp before",
                      }
                    : item.operator === "__gte"
                    ? {
                        label: "Is a timestamp greater than",
                        value: "Is a timestamp greater than",
                      }
                    : item.operator === "__lte"
                    ? {
                        label: "Is a timestamp less than",
                        value: "Is a timestamp less than",
                      }
                    : item.operator === "" && item.exclude === 0
                    ? { label: "Is equal to", value: "Is equal to" }
                    : { label: "Is not equal to", value: "Is not equal to" },
                value:
                  item.operator === "__gte"
                    ? getDateDiff(item.value)
                    : item.operator === "__lte"
                    ? getDateDiff(item.value)
                    : item.operator === "__gt" || item.operator === "__lt"
                    ? new Date(item.value)
                    : isDropdownRequired(attribute)
                    ? { label: item.value, value: item.value }
                    : attribute === "report__location_address"
                    ? {
                        label: item.value.replace(/\^/g, ","),
                        value: item.value,
                      }
                    : attribute === "report__radiologist_id"
                    ? { label: item.valueLabel, value: item.value }
                    : item.value,
                exclude: item.exclude,
                attribute: {
                  label: label,
                  value: attribute,
                },
              },
            ],
          };
        }
      });
      setMainRows(arr);
  
    }
  }, [dispatch, cohortFilters]);

  const generateOptions = (interval) => {
    const maxAge = 100;
    const newOptions = [];
    let start = 1;

    const numGroups = Math.floor(maxAge / interval);
    let end = 0;

    for (let i = 0; i < numGroups; i++) {
      end = start + interval - 1;

      newOptions.push({
        value: `${start}-${end}`,
        label: `${start}-${end}`,
        category: "age",
      });

      start = end + 1;
    }

    if (
      interval === 5 ||
      interval === 10 ||
      interval === 20 ||
      interval === 25 ||
      interval === 50
    ) {
      newOptions.push({
        value: `101-150`,
        label: `101+`,
        category: "age",
      });
    } else if (interval === 15 || interval === 30) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    } else if (interval === 35) {
      newOptions.push({
        value: `71-150`,
        label: `71+`,
        category: "age",
      });
    } else if (interval === 40) {
      newOptions.push({
        value: `81-150`,
        label: `81+`,
        category: "age",
      });
    } else if (interval === 45) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    }

    setAgeOptions(newOptions);
  };

  useEffect(() => {
    generateOptions(recoConfigData?.patientAgeInterval ?? 5);
  }, [recoConfigData]);

  useEffect(() => {
    dispatch(getRadiologistForDropdown());

    dispatch(getFilterValues()).then((res) => {
      if (res) {
        setFilterOptions(res);
      }
    });
  }, []);

  const [locationOptions, setLocationOptions] = useState();
  const [nextLocationPage, setNextLocationPage] = useState();
  const [loadingMoreLocations, setLoadingMoreLocations] = useState(false);

  useEffect(() => {
    dispatch(getLocations()).then((res) => {
      if (res) {
        if (res.next) {
          setNextLocationPage(2);
        }
        setLocationOptions(
          res.results.map((itm) => ({
            category: "location",
            label: itm.location_address.replace(/\^/g, ","),
            value: itm.location_address,
          }))
        );
      }
    });
  }, []);

  const loadMoreLocationOptions = async () => {
    if (nextLocationPage && !loadingMoreLocations) {
      setLoadingMoreLocations(true);
      dispatch(getLocations(nextLocationPage)).then((res) => {
        setLoadingMoreLocations(false);
        if (res) {
          setLocationOptions((prev) => [
            ...prev,
            ...res.results.map((itm) => ({
              category: "location",
              label: itm.location_address.replace(/\^/g, ","),
              value: itm.location_address,
            })),
          ]);
          if (res.next) {
            setNextLocationPage((prev) => prev + 1);
          } else {
            setNextLocationPage();
          }
        }
      });
    }
  };

  const addMainRow = () => {
    setMainRows((prevRows) => [
      ...prevRows,
      {
        subGroupCondition: "and",
        subGroups: [{ attribute: "", operator: "", value: "" }],
      },
    ]);
  };

  const addSubGroup = (mainRowIndex) => {
    setMainRows((prevRows) =>
      prevRows.map((row, index) =>
        index === mainRowIndex
          ? {
              ...row,
              subGroups: [
                ...row.subGroups,
                { attribute: "", operator: "", value: "" },
              ],
            }
          : row
      )
    );
  };

  const removeMainRow = (index) => {
    if (mainRows.length > 1) {
      setMainRows((prevRows) => prevRows.filter((_, i) => i !== index));
    }
  };

  const removeSubGroup = (mainRowIndex, subGroupIndex) => {
    setMainRows((prevRows) =>
      prevRows.map((row, index) =>
        index === mainRowIndex
          ? {
              ...row,
              subGroups: row.subGroups.filter((_, i) => i !== subGroupIndex),
            }
          : row
      )
    );
  };

  const updateSubGroupField = (mainRowIndex, subGroupIndex, field, value) => {
    setMainRows((prevRows) =>
      prevRows.map((row, index) =>
        index === mainRowIndex
          ? {
              ...row,
              subGroups: row.subGroups.map((sub, i) =>
                i === subGroupIndex
                  ? field === "attribute"
                    ? { [field]: value, operator: "", value: "" }
                    : field === "operator"
                    ? { ...sub, [field]: value, value: "" }
                    : field === "value"
                    ? { ...sub, [field]: value }
                    : { ...sub }
                  : sub
              ),
            }
          : row
      )
    );
  };

  const toggleSubGroupCondition = (mainRowIndex) => {
    setMainRows((prevRows) =>
      prevRows.map((row, index) =>
        index === mainRowIndex
          ? {
              ...row,
              subGroupCondition: row.subGroupCondition === "and" ? "or" : "and",
            }
          : row
      )
    );
  };

  const DatePickerInput = React.forwardRef((props, ref) => {
    const { onClick, date, time } = props;
    const formatDate = (date, time) => {
      return `${moment(date).format("ll")}`;
    };
    return (
      <DatePickerButton
        onClick={onClick}
        style={{ padding: "8px 24px", height: "38px" }}
      >
        <DateContainer style={{ fontSize: 17 }}>
          {date ? `${formatDate(date, time)}` : "Pick any date"}
        </DateContainer>
        <CalendarIcon />
      </DatePickerButton>
    );
  });

  const isDropdownRequired = (attribute) => {
    if (
      needsToBeBooleanValues.includes(attribute) ||
      needsToBeDropdownValues.includes(attribute) ||
      needsToBeFixedDropdownValues.includes(attribute)
    )
      return true;
    return false;
  };

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removeIdx, setRemoveIdx] = useState({ mainRow: "", subGroup: "" });

  const dialogHandler = (idx, subIdx) => {
    setRemoveIdx({ mainRow: idx, subGroup: subIdx });
    setRemoveDialogOpen(!removeDialogOpen);
  };

  const [isLoadingDeleteCohort, setIsLoadingDeleteCohort] = useState(false);

  const cohortSaveHandler = () => {
    for (const mainRow of mainRows) {
      for (const subGroup of mainRow.subGroups) {
        if (
          !cohortName ||
          !subGroup.attribute ||
          !subGroup.operator ||
          !subGroup.value
        ) {
          alert("Please fill all fields in every row before saving.");
          return;
        }
      }
    }

    let arr = [];


    mainRows.map((itm) => {
      let newArr = [];
      itm.subGroups.map((item) => {
        let today = moment();
        let op =
          item.operator?.value === "Does not contain"
            ? "__icontains"
            : item.operator?.value === "Is equal to"
            ? ""
            : item.operator?.value === "Contains"
            ? "__icontains"
            : item.operator?.value === "Is a timestamp after"
            ? "__gt"
            : item.operator?.value === "Is a timestamp before"
            ? "__lt"
            : item.operator?.value === "Is a timestamp greater than"
            ? "__gte"
            : item.operator?.value === "Is a timestamp less than"
            ? "__lte"
            : "";
        return newArr.push({
          label: item?.attribute?.label,
          operator: op,
          valueLabel: item.value?.label ?? item.value,
          attribute: item.attribute?.value,
          exclude:
            item.operator?.value === "Is not equal to" ||
            item.operator?.value === "Does not contain"
              ? 1
              : 0,
          value:
            (item.attribute?.value === "nlp_range_start" ||
              item.attribute?.value === "nlp_range_end" ||
              item.attribute?.value === "report__initial_exam_date") &&
            item.operator?.value === "Is a timestamp greater than"
              ? today.add(item.value, "days").format("YYYY-MM-DD")
              : (item.attribute?.value === "nlp_range_start" ||
                  item.attribute?.value === "nlp_range_end" ||
                  item.attribute?.value === "report__initial_exam_date") &&
                item.operator?.value === "Is a timestamp less than"
              ? today.subtract(item.value, "days").format("YYYY-MM-DD")
              : ((item.operator?.value === "Is equal to" ||
                  item.operator?.value === "Is not equal to" ||
                  item.operator?.value === "Contains" ||
                  item.operator?.value === "Does not contain") &&
                  !needsToBeDateInputValues.includes(item.attribute?.value)) ||
                ((item.operator?.value === "Is a timestamp greater than" ||
                  item.operator?.value === "Is a timestamp less than") &&
                  needsToBeDateInputValues.includes(item.attribute?.value))
              ? item.dimension?.value === "Patient" ||
                item.dimension?.value === "Physician"
                ? item.value?.value ?? item.value
                : item.value?.value ?? item.value
              : moment(item.value).format("yyyy-MM-DD"),
        });
      });

      if (newArr.length > 1) {
        arr.push({
          condition: itm.subGroupCondition,
          filters: newArr,
        });
      } else {
        arr.push(newArr[0]);
      }
    });

    let todayDate = moment();

    const dataToSend = {
      title: cohortName,
      bypass_validation: isChecked,
      raw_filters: {
        condition: selectedOperator.value,
        filters: arr,
      },
    };
    if (isEditClicked) {
      let id = props.location.state.data.id;
      dispatch(updateRecoCohort(id, dataToSend)).then(() => {
        history.push(
          `/auth/radAdmin/followUp/recommendations/cohorts/details/${id}`
        );
      });
    } else {
      dispatch(createRecoCohort(dataToSend)).then((res) => {
        if (res && res.success) {
          history.push("/auth/radAdmin/followUp/recommendations/cohorts");
        }
      });
    }
  };

  return (
    <Box p={30}>
      {removeDialogOpen && (
        <RemoveConfirmation
          content="Are you sure you want to remove this Dimension?"
          openDialog={removeDialogOpen}
          dialogHandler={dialogHandler}
          isLoading={isLoadingDeleteCohort}
          deleteHandler={() => {
            if (removeIdx.subGroup) {
              removeSubGroup(removeIdx.mainRow, removeIdx.subGroup);
            } else {
              removeMainRow(removeIdx.mainRow);
            }
            setRemoveIdx({ mainRow: "", subGroup: "" });
            setRemoveDialogOpen(false);
          }}
          removeHeader={"Yes, Remove Dimension"}
          heading={"Remove Dimension"}
        />
      )}
      <HeaderContainer>
        <PageTitle color="#00AEEF">
          {isEditClicked
            ? "Recommendation Tracking List Edit"
            : "Recommendation Tracking List Creation"}
        </PageTitle>
      </HeaderContainer>


      <Card
        style={{ paddingBottom: "30px", paddingTop: "30px", textAlign: "left" }}
      >
        <Container>
          <Section>
            <SelectContainer>
              <SelectDropdownTitle>
                Recommendation Tracking List Name *
              </SelectDropdownTitle>
              <TextField
                id="outlined-uncontrolled"
                variant="outlined"
                value={cohortName}
                onChange={(e) => setCohortName(e.target.value)}
                className={classes.title}
                placeholder="Create list name"
                InputProps={{
                  style: {
                    height: "38px",
                  },
                }}
              />
            </SelectContainer>
          </Section>
          <RowContainer>
           
              <div>
                <CheckBoxLabel>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />

                  <CheckboxTitle>Bypass validation</CheckboxTitle>
                </CheckBoxLabel>
              </div>
          
          </RowContainer>
          {mainRows.map((mainRow, mainRowIndex) => (
            <>
              {mainRowIndex > 0 && (
                <SwitchContainer>
                  <SwitchButton
                    active={selectedOperator.value === "and"}
                    onClick={() => {
                      setSelectedOperator({
                        value: "and",
                        label: "AND",
                        category: "Operator",
                      });
                    }}
                  >
                    and
                  </SwitchButton>
                  <SwitchButton
                    active={selectedOperator.value === "or"}
                    onClick={() => {
                      setSelectedOperator({
                        value: "or",
                        label: "OR",
                        category: "Operator",
                      });
                    }}
                  >
                    or
                  </SwitchButton>
                </SwitchContainer>
                // <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                //   <button onClick={() => toggleMainRowCondition()}>
                //     {mainRowCondition}
                //   </button>
                // </div>
              )}
              <div
                key={mainRowIndex}
                style={{
                  border: "1px solid gray",
                  padding: "10px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div></div>

                {mainRow.subGroups.map((subGroup, subGroupIndex) => (
                  <>
                    {subGroupIndex > 0 && (
                      <SwitchContainer>
                        <SwitchButton
                          active={mainRow.subGroupCondition === "and"}
                          onClick={() => {
                            toggleSubGroupCondition(mainRowIndex);
                          }}
                        >
                          and
                        </SwitchButton>
                        <SwitchButton
                          active={mainRow.subGroupCondition === "or"}
                          onClick={() => {
                            toggleSubGroupCondition(mainRowIndex);
                          }}
                        >
                          or
                        </SwitchButton>
                      </SwitchContainer>
                    )}
                    <div
                      key={subGroupIndex}
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <SelectContainer>
                        <SelectDropdownTitle>Attribute *</SelectDropdownTitle>
                        <Select
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          classNamePrefix="select"
                          options={filterOption.map((itm) => ({
                            label: capitalize(itm.label?.replace(/_/g, " ")),
                            value: itm.key,
                          }))}
                          value={subGroup.attribute}
                          onChange={(itm) => {
                            updateSubGroupField(
                              mainRowIndex,
                              subGroupIndex,
                              "attribute",
                              itm
                            );
                            if (needsToBeDropdownValues.includes(itm?.value)) {
                              dispatch(
                                getDropdownValuesForCohortRecommendation(
                                  itm.value
                                )
                              );
                            }
                            // setValue("Values");
                            // setOperator(null);
                          }}
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable
                        />
                      </SelectContainer>

                      <SelectContainer>
                        <SelectDropdownTitle>Operator *</SelectDropdownTitle>
                        <Select
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          classNamePrefix="select"
                          options={
                            subGroup.attribute?.value ===
                            "report__radiologist_id"
                              ? messageOnlyOperator.map((itm) => ({
                                  label: itm,
                                  value: itm,
                                }))
                              : needsToBeDropdownValues.includes(
                                  subGroup.attribute?.value
                                )
                              ? operatorsArrayDropdownOnly.map((itm) => ({
                                  label: itm,
                                  value: itm,
                                }))
                              : needsToBeBooleanValues.includes(
                                  subGroup.attribute?.value
                                )
                              ? operatorsArrayBooleanOnly.map((itm) => ({
                                  label: itm,
                                  value: itm,
                                }))
                              : needsToBeDateInputValues.includes(
                                  subGroup.attribute?.value
                                )
                              ? operatorsArrayDate.map((itm) => ({
                                  label: itm,
                                  value: itm,
                                }))
                              : operatorsArrayText.map((itm) => ({
                                  label: itm,
                                  value: itm,
                                }))
                          }
                          value={subGroup.operator}
                          onChange={(itm) => {
                            updateSubGroupField(
                              mainRowIndex,
                              subGroupIndex,
                              "operator",
                              itm
                            );
                          }}
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable
                        />
                      </SelectContainer>
                      {subGroup.attribute && subGroup.operator && (
                        <SelectContainer>
                          <SelectDropdownTitle>value *</SelectDropdownTitle>
                          {subGroup.attribute.value === "age_group" ? (
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: RadioOption,
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={ageOptions}
                              value={subGroup.value}
                              onChange={(itm) => {
                                updateSubGroupField(
                                  mainRowIndex,
                                  subGroupIndex,
                                  "value",
                                  itm
                                );
                              }}
                              styles={{
                                option: (styles) => ({
                                  ...styles,
                                  fontSize: "14px",
                                }),
                                control: (styles) => ({
                                  ...styles,
                                  borderRadius: "4px",
                                }),
                              }}
                              isClearable
                            />
                          ) : subGroup.attribute.value ===
                            "report__radiologist_id" ? (
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: RadioOption,
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={radiologistOptions}
                              value={subGroup.value}
                              onChange={(itm) => {
                                updateSubGroupField(
                                  mainRowIndex,
                                  subGroupIndex,
                                  "value",
                                  itm
                                );
                              }}
                              styles={{
                                option: (styles) => ({
                                  ...styles,
                                  fontSize: "14px",
                                }),
                                control: (styles) => ({
                                  ...styles,
                                  borderRadius: "4px",
                                }),
                              }}
                              isClearable
                            />
                          ) : subGroup.attribute.value ===
                            "report__location_address" ? (
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: RadioOption,
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={locationOptions}
                              value={subGroup.value}
                              closeMenuOnSelect={false}
                              onChange={(itm) => {
                                updateSubGroupField(
                                  mainRowIndex,
                                  subGroupIndex,
                                  "value",
                                  itm
                                );
                              }}
                              styles={{
                                option: (styles) => ({
                                  ...styles,
                                  fontSize: "14px",
                                }),
                                control: (styles) => ({
                                  ...styles,
                                  borderRadius: "4px",
                                }),
                              }}
                              onMenuScrollToBottom={loadMoreLocationOptions}
                              isLoading={loadingMoreLocations}
                              isClearable
                            />
                          ) : isDropdownRequired(subGroup.attribute?.value) &&
                            subGroup.operator !== "Is a timestamp after" &&
                            subGroup.operator !== "Is a timestamp before" &&
                            !needsToBeDateInputValues.includes(
                              subGroup.attribute?.value
                            ) ? (
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: RadioOption,
                              }}
                              classNamePrefix="select"
                              options={
                                needsToBeDropdownValues.includes(
                                  subGroup.attribute?.value
                                )
                                  ? getFixedValues(
                                      subGroup.attribute?.value
                                    )?.map((itm) => ({
                                      label: itm,
                                      value: itm,
                                    }))
                                  : booleanValues.map((itm) => ({
                                      label: itm,
                                      value: itm,
                                    }))
                              }
                              value={subGroup.value}
                              onChange={(itm) => {
                                updateSubGroupField(
                                  mainRowIndex,
                                  subGroupIndex,
                                  "value",
                                  itm
                                );
                              }}
                              styles={{
                                option: (styles) => ({
                                  ...styles,
                                  fontSize: "14px",
                                }),
                              }}
                              isClearable
                            />
                          ) : ((subGroup.operator?.value === "Is equal to" ||
                              subGroup.operator?.value === "Is not equal to" ||
                              subGroup.operator?.value === "Operators" ||
                              subGroup.operator?.value === "Contains" ||
                              subGroup.operator?.value ===
                                "Does not contain") &&
                              !needsToBeDateInputValues?.includes(
                                subGroup.attribute?.value
                              )) ||
                            ((subGroup.operator?.value ===
                              "Is a timestamp greater than" ||
                              subGroup.operator?.value ===
                                "Is a timestamp less than") &&
                              needsToBeDateInputValues?.includes(
                                subGroup.attribute?.value
                              )) ? (
                            <TextField
                              id="outlined-uncontrolled"
                              variant="outlined"
                              type={
                                subGroup.operator?.value ===
                                  "Is a timestamp greater than" ||
                                subGroup.operator?.value ===
                                  "Is a timestamp less than"
                                  ? "number"
                                  : "text"
                              }
                              value={subGroup.value}
                              onChange={(itm) => {
                                updateSubGroupField(
                                  mainRowIndex,
                                  subGroupIndex,
                                  "value",
                                  itm.target.value
                                );
                              }}
                              className={classes.textField}
                              placeholder={
                                subGroup.operator?.value ===
                                  "Is a timestamp greater than" ||
                                subGroup.operator?.value ===
                                  "Is a timestamp less than"
                                  ? "Enter # of days from today"
                                  : "Enter value"
                              }
                              InputProps={{
                                style: {
                                  height: "38px",
                                },
                              }}
                            />
                          ) : (
                            <DatePicker
                              onChange={(itm) => {
                                updateSubGroupField(
                                  mainRowIndex,
                                  subGroupIndex,
                                  "value",
                                  itm
                                );
                              }}
                              dateFormat="MM/dd/yyyy"
                              selected={subGroup.value}
                              customInput={
                                <DatePickerInput date={subGroup.value} />
                              }
                              className={classes.datePicker}
                              popperPlacement="bottom"
                              popperModifiers={{
                                flip: { behavior: ["bottom"] },
                                preventOverflow: { enabled: false },
                                hide: { enabled: false },
                              }}
                            />
                          )}
                        </SelectContainer>
                      )}

                      {mainRow.subGroups.length > 1 && (
                        <SelectContainer style={{ width: "fit-content" }}>
                          <SelectDropdownTitle style={{ visibility: "hidden" }}>
                            delete
                          </SelectDropdownTitle>
                          <Remove
                            onClick={() => {
                              dialogHandler(mainRowIndex, subGroupIndex);
                            }}
                          >
                            <IconContainer>
                              <RemoveRecoIcon />
                              <RemoveRecoIconHover className="light" />
                            </IconContainer>
                          </Remove>
                        </SelectContainer>
                        // <button
                        //   onClick={() =>
                        //     removeSubGroup(mainRowIndex, subGroupIndex)
                        //   }
                        // >
                        //   Remove Sub-group
                        // </button>
                      )}
                    </div>
                  </>
                ))}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 40,
                    gap: "20px",
                  }}
                >
                  <Button
                    classes={{
                      label: classes.label,
                      root: classes.additionalSubDimensionsButton,
                    }}
                    onClick={() => addSubGroup(mainRowIndex)}
                  >
                    Add Additional Sub Dimensions
                  </Button>

                  {mainRows.length > 1 && (
                    <Button
                      classes={{
                        label: classes.label,
                        root: classes.removeAllSubDimensionsButton,
                      }}
                      onClick={() => {
                        dialogHandler(mainRowIndex);
                      }}
                      // onClick={() => removeMainRow(mainRowIndex)}
                    >
                      Remove All Sub Dimensions
                    </Button>
                  )}
                </div>
              </div>
            </>
          ))}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 40,
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            <Button
              classes={{
                label: classes.label,
                root: classes.additionalDimensionsButton,
              }}
              onClick={addMainRow}
            >
              Add Additional Dimensions
            </Button>
            <Button
              classes={{ label: classes.label, root: classes.saveButton }}
              onClick={() => cohortSaveHandler()}
            >
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Save Recommendation Tracking List"
              )}
            </Button>
          </div>
        </Container>
      </Card>
    </Box>
  );
};

export default Create;
