import React, { useEffect } from 'react';
import styled from 'styled-components';
import Flex from '../../../../components/_styles/Flex';
import { ButtonsContainer, CancelImport, Content, NextButton } from './ReservedAttributes';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { isNil } from 'lodash';

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;

const TableContainer = styled(Flex)`
  flex-direction: column;
`;

const Title = styled(Flex)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3F4857;
`;

const Text = styled(Flex)`
  font-size: 16px;
  line-height: 22px;
  color: #3F4857;
`;

const Table = styled.table`
  width: 100%;
overflow: auto;
display: inline-block;
`

const TableHeaderCell = styled.th`
  font-size: 16px;
  font-weight: bold;
  color: #3F4857;
  border: 1px solid #C2CCD9;
  border-right: none;
  &:last-child {
    border-right: 1px solid #C2CCD9;
    padding-right: 16px !important;
  }
`

const TableCell = styled.td`
  font-size: 14px;
  color: #3F4857;
  border: 1px solid #C2CCD9;
  border-right: none;
  border-top: none;
  text-align: ${props => props.isFirstColumn ? 'center' : ''}
  padding-right: ${props => props.isFirstColumn ? '0' : '16px'}
  &:last-child {
    border-right: 1px solid #C2CCD9;
    padding-right: 16px !important;
  }
`

const TableTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #0C2945;
  margin-top: 50px;
  margin-bottom: 10px;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 100%;
`;


const Confirm = ({ previewRows, headersMapping, appHeaders, cancelImportAll, onFinishImport, files, cancelImportFile }) => {
  useEffect(() => {
    onFinishImport()
  }, [])

  const importProgressBarValue = useSelector((state) => state.radAdmin.importProgressBarValue);

  if (!isNil(importProgressBarValue)) {
    return <StyledProgressBar now={importProgressBarValue} />
  }

  return null;

  return (
    <Container>
      <Content>
        <ButtonsContainer>
          <NextButton primary block onClick={onFinishImport}>Finish Import</NextButton>
          <CancelImport danger block onClick={cancelImportAll}>Cancel Import All</CancelImport>
        </ButtonsContainer>
        {!isNil(importProgressBarValue) && (
          <StyledProgressBar now={importProgressBarValue} />
        )}
        { headersMapping && headersMapping.map((item, outerIdx) => {
           const {fileName, ...headersForPrint} = item;
           const keys = headersForPrint && Object.keys(headersForPrint);
          return (
        <TableContainer key={outerIdx}>
          <Title>Custom Attributes</Title>
          <Text>This is the data we extracted from your CSV file {fileName}</Text>
          <CancelImport danger blocl onClick={() => cancelImportFile(fileName)} style={{marginTop: '20px'}}>Cancel Import File </CancelImport>
          <TableTitle>Your CSV Preview</TableTitle>
          <Table>
            <tbody>
            <tr>
              <TableHeaderCell/>
              {keys && keys.map(userHeaderKey => {
                let displayValue;
                const mappedAppHeaderKey = headersMapping[userHeaderKey];
                if (mappedAppHeaderKey) {
                  const appHeader = appHeaders.find(appHeader => appHeader.key === mappedAppHeaderKey)
                  displayValue = appHeader ? appHeader.name : userHeaderKey;
                } else {
                  displayValue = userHeaderKey;
                }
                return (
                  <TableHeaderCell key={JSON.stringify(userHeaderKey)}>{displayValue}</TableHeaderCell>
                )
              })}
            </tr>
            </tbody>
            {previewRows && previewRows[outerIdx] && previewRows[outerIdx].map((row, index) => (
              <tbody key={index}>
                <tr>
                  <TableCell isFirstColumn>{index + 1}</TableCell>
                    {keys && keys.map(userHeaderKey => (
                      <TableCell key={JSON.stringify(userHeaderKey)}>{row[userHeaderKey]}</TableCell>
                    ))}
                </tr>
              </tbody>
            ))}
          </Table>

        </TableContainer>
        )})}
      </Content>
    </Container>
  )
};

export default Confirm;
