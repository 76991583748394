import React, { useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";

import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
  width: 750px;
  margin: auto;
  border-radius: 4px;
  background-color: white;
`;

const Card = styled(Flex)`
  min-width: max-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  media (max-width: 657px) {
    margin-left: 78px;
  }
`;

const FormContainer = styled.div`
  // width: 230px;
`;

const SelectContainer = styled.div`
  width: ${(props) => props.width || "227px"};
  margin-bottom: 14px;
`;

const FilterMenuTitle = styled.div`
  margin-bottom: 0px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #0c2945;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 163px;
  height: 38px;
  background-color: ${(props) => props.bg};
  border-radius: 4px;
  border: ${(props) => props.border || 0};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => props.color};
  display: block;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  &:hover {
    background: ${({ isPrimary }) => (isPrimary ? "transparent" : "#CFCFCF")};
    color: ${({ isPrimary }) => (isPrimary ? "#00aeef" : "white")};
    border: ${({ isPrimary }) =>
      isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF"};
  }
`;

const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "10px"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
`;

export const months = [
  { value: "01", label: "January", category: "month" },
  { value: "02", label: "February", category: "month" },
  { value: "03", label: "March", category: "month" },
  { value: "04", label: "April", category: "month" },
  { value: "05", label: "May", category: "month" },
  { value: "06", label: "June", category: "month" },
  { value: "07", label: "July", category: "month" },
  { value: "08", label: "August", category: "month" },
  { value: "09", label: "September", category: "month" },
  { value: "10", label: "October", category: "month" },
  { value: "11", label: "November", category: "month" },
  { value: "12", label: "December", category: "month" },
];

const IndicatorsContainer = (props) => {
  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const RadioOption = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

export const PatientListFilters = (props) => {
  const {
    setSelectedFilters,
    selectedFilters,
    showModal,
    onClose,
    handleSave,
  } = props;

  const [selectedValues, setSelectedValues] = useState(selectedFilters);

  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => ({
    value: 1900 + i,
    label: String(1900 + i),
    category: "year",
  }));

  const days = Array.from({ length: 31 }, (_, i) => ({
    value: String(i + 1).padStart(2, "0"),
    label: String(i + 1).padStart(2, "0"),
    category: "day",
  }));

  const handleChange = (field) => (selectedOption) => {
    setSelectedValues((prevData) => ({
      ...prevData,
      [field]: selectedOption ? [selectedOption] : [],
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setSelectedFilters(selectedValues);

    handleSave(selectedValues);
    onClose();
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <FilterMenuTitle>Filters </FilterMenuTitle>
            <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
          </div>

          <div style={{ width: "100%" }}>
            <FormContainer>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <Row style={{ marginBottom: "20px" }}>
                    <SelectContainer>
                      <SelectDropdownTitle>
                        Patient DOB Year
                      </SelectDropdownTitle>
                      <Select
                        components={{
                          IndicatorsContainer,
                          Option: RadioOption,
                        }}
                        options={years}
                        value={selectedValues?.year?.[0]}
                        onChange={handleChange("year")}
                        placeholder="Year"
                        isClearable={true}
                        styles={{
                          option: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                          }),
                        }}
                      />
                    </SelectContainer>

                    <SelectContainer>
                      <SelectDropdownTitle>
                        Patient Dob Month
                      </SelectDropdownTitle>
                      <Select
                        components={{
                          IndicatorsContainer,
                          Option: RadioOption,
                        }}
                        options={months}
                        value={selectedValues?.month?.[0]}
                        onChange={handleChange("month")}
                        placeholder="Month"
                        isClearable={true}
                        styles={{
                          option: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                          }),
                        }}
                      />
                    </SelectContainer>

                    <SelectContainer>
                      <SelectDropdownTitle>Patient Dob Day</SelectDropdownTitle>
                      <Select
                        components={{
                          IndicatorsContainer,
                          Option: RadioOption,
                        }}
                        options={days}
                        value={selectedValues?.day?.[0]}
                        onChange={handleChange("day")}
                        placeholder="Day"
                        isClearable={true}
                        styles={{
                          option: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                          }),
                        }}
                      />
                    </SelectContainer>
                  </Row>

                  <Row justifyContent="flex-end" gap="18px">
                    <Button
                      color="#000000"
                      border="2px solid #CFCFCF"
                      bg="#FFF"
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      bg="#00AEEF"
                      color="#FFFFFF"
                      isPrimary={true}
                    >
                      Apply Filters
                    </Button>
                  </Row>
                </div>
              </form>
            </FormContainer>
          </div>
        </Card>
      </ModalContentContainer>
    </Modal>
  );
};
