import { reducer as reduxForm } from 'redux-form';
import auth from './authReducer';
import flash from './flashReducer';
import apiErrors from './apiErrorsReducer';
import constants from './constants';
import radAdmin from './radAdmin';

export default {
    form: reduxForm,
    auth,
    flash,
    apiErrors,
    constants,
    radAdmin
};
