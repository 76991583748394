import React, { useState, useEffect, useCallback } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import styled from 'styled-components';
import print from 'print-js';

import { ReactComponent as IconDownload } from '../../assets/download.svg';
import { ReactComponent as IconPrint } from '../../assets/print.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Button = styled.button.attrs(({ type }) => ({
    type: type ?? 'button'
  }))`
    padding: 15px 50px;
    border-radius: 5px;
    border: none;
    outline: none !important;
    background: #00AEEF;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #f9fafc;
  
    &:disabled {
      border: 1px solid #d2d3d7;
      background: #f9fafc;
      color: #d2d3d7;
    }
  `;
  

const Header = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin-bottom:20px;
`;

const IconGroup = styled.div`
  svg {
    cursor: pointer;
  }

  svg:first-child {
    margin-right: 30px;
  }
`;

const PDFContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  background: #fff;
  border: 1px solid gray;
  border-radius: 20px;
  overflow: auto; 
`;

const ControlsButton = styled(Button)`
  margin: 8px;
`;

const PDFViewer = ({ src, createLocalURL }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [localURL, setLocalURL] = useState('');

  const onDownload = useCallback(async () => {
    const link = document.createElement('a');
    link.download = 'report.pdf';
    if (createLocalURL) {
      link.href = localURL;
      link.click();
    } else {
      const blob = await fetch(src).then((r) => r.blob());
      link.href = URL.createObjectURL(blob);
      link.click();
    }
  }, [createLocalURL, localURL, src]);

  const onPrint = useCallback(async () => {
    if (createLocalURL) {
      print(localURL, 'pdf');
    } else {
      const blob = await fetch(src).then((r) => r.blob());
      print(URL.createObjectURL(blob), 'pdf');
    }
  }, [createLocalURL, localURL, src]);

  useEffect(() => {
    if (createLocalURL) {
      const file = new Blob([src], { type: 'application/pdf' });
      setLocalURL(URL.createObjectURL(file));
    }
  }, [src, createLocalURL]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <PDFContainer>
      <Header>
        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        <ControlsButton disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </ControlsButton>
        <ControlsButton disabled={pageNumber >= numPages} onClick={nextPage}>
          Next
        </ControlsButton>
        <IconGroup>
          <IconDownload onClick={onDownload} />
          <IconPrint onClick={onPrint} />
        </IconGroup>
      </Header>
      <Document file={localURL || src} onLoadSuccess={onDocumentLoadSuccess} >
        <Page pageNumber={pageNumber} scale={1.5}/>
      </Document>
    </PDFContainer>
  );
};

export default PDFViewer;
