import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/DetailsTable";
import { Card as CardContainer } from "../../../styles";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Card } from "../../../components/DetailsTable";
import { LoadMoreContainer } from "../../../components/Dialog/EditFormBottom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Spinner } from "react-bootstrap";
import { isNull, isEmpty } from "lodash";
import moment from "moment";
import Select, { components } from "react-select";
import validator from "validator";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  getPatientById,
  getAppointmentByPatientId,
  getReportByPatientId,
  getRecommendationByPatientId,
  getMessagesByPatientPhoneNumber,
  getNotes,
  getPrescriptionByPatientId,
  getEmailsByPatientId,
  getPatientByMrn,
  updatePatientDetails,
  getCohortsByPatientId,
  changeActionStatusActionPatientDetails
} from "../../../actions/radminUploads";
import { InfoIconOutline as InfoButtonIcon } from "../Messages/Icons";
import { TemplateButton } from "../Messages/SmsCreationDialog";
import { StatusBadge } from "../Messages/Messages";
import { StatusBadge as MessageStatus } from "../Messages/MessageDeliveriesIncoming";
import ShowMessageContent from "../Messages/ShowMessageContent";
import EditReco from "../../../components/Dialog/EditReco";
import { PaginationLeftArrow, PaginationRightArrow } from "../Cohorts/Icons";
import Flex from "../../../components/_styles/Flex";
import { Popover } from '@material-ui/core';
import ActionStatusList from "../FollowUpList/ActionStatusList";
import NewNote from "../../../components/Dialog/NewNote";
import { showAlert } from "../../../actions/radminUploads";
import { ReactComponent as ConditionalIcon } from '../../../assets/conditional-icon.svg';
import ViewReportModal from "../Messages/ViewReportModal";


const Heading = styled.p`
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    font-family: 'Roboto Condensed';
    color: #0C2945;
`;
const PatientInfoHeading = styled.p`
  color: #B2B2B3;
  font-size: 14px;
  font-weight: 600;
  font-family: Open Sans;
  line-height: 15.08px;
  margin-bottom:8px;
`;
const PatientInfo = styled.p`
  font-size: 20px;
  color: #3e3f41;
  font-size: 14px;
  font-weight: 400;
  font-family: Open Sans;
  line-height: 17.6px;
`;
const PatientName = styled.p`
  font-size: 20px;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  font-family: Open Sans;
  line-height: 17.6px;
  margin-bottom:0px;
`;
const Container = styled.div`
  margin: 0 auto;
  width: 95%;
  margin-top: 27px;
`;

export const NotesContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  padding:  23px;
  height: 25rem;
`;
export const TableContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  padding: 30px;
`;

const PageContainer = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items:center;
`;
const IconContainer = styled.div`
  cursor: pointer;
  padding-right: ${(props) => props.paddingRight};
  padding-left: ${(props) => props.paddingLeft};
  display:flex;
  align-items:center;
  gap:4px;
`;

const SelectContainer = styled.div`
  width: 250px;
  & div.select__control, div.select__value-container {
    height: 38px;
   }
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;


const ContainerHeader = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:25px;
`

const languageOptions = [
  { value: "en", label: "English (default)" },
  { value: "spa", label: "Spanish" },
];

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "non_binary", label: "Non Binary" },
  { value: "other", label: "Other" },
  { value: "unknown", label: "Unknown" },
];

const phoneClosedOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const phoneUnsubscribedOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const IndicatorsContainer = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const Badge = styled(Flex)`
  width: 140px;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-left:0;
  border-radius: 100px;
  color: white;
  background: ${(props) => {
    switch (props.status) {
      case "sent":
        return "#FDBB13";

      case "delivered":
        return "#64C67C";

      case "opened":
        return "#00AEEF";

      case "clicked":
        return "#4EAC9F";

      case "spammed":
        return "#7C7C7C";

      case "failed":
        return "#9B64C6";

      case "dropped":
        return "#CF3131";

      case "bounced":
        return "#FF5F65";

      case "unsubscribed":
        return "#ADADAD";

      case "converted":
        return "#FF7A00";

      default:
        return "#DFE0EB";
    }
  }};
`;
const EmailStatusBadge = ({ status }) => (
  <Badge status={status}>{status}</Badge>
);

const recommendationColumns = [
  {
    key: "accessionNumber",
    name: "Accession Number",
  },
  {
    key: "initialExamDate",
    name: "Exam Date",
  },
  {
    key: "modality",
    name: "Reco Modality",
  },
  {
    key: "anatomy",
    name: "Reco Anatomy",
  },
  {
    key: "rangeStart",
    name: "Range Start",
  },
  {
    key: "rangeEnd",
    name: "Range End",
  },
];

const reportColumns = [
  {
    key: "accessionNumber",
    name: "Accession Number",
  },
  {
    key: "modality",
    name: "Modality",
  },
  {
    key: "initialExamDate",
    name: "Exam Date",
  },
];

const appointmentsColumns = [
  {
    key: "initial_exam_date",
    name: "Scheduled Exam Date",
  },
  {
    key: "exam_description",
    name: "Exam Description",
  },
  {
    key: "care_type",
    name: "Care Type",
  },
];

const prescriptionsColumns = [
  {
    key: "id",
    name: "ID",
  },
  {
    key: "accession_number",
    name: "Accession Number",
  },
  {
    key: "referring_physician",
    name: "Referring Physician",
  },
  {
    key: "patient",
    name: "Patient",
  },
  {
    key: "exam_description",
    name: "Exam Description",
  },
  {
    key: "modality",
    name: "Modality",
  },
  {
    key: "ordered_time",
    name: "Ordered Time",
  },
];

const messagesColumns = [
  {
    key: "created",
    name: "Created Date",
  },
  {
    key: "twilio_status",
    name: "Status",
    displayCell: () => <MessageStatus />,
  },
  {
    key: "direction",
    name: "Direction",
  },
  {
    key: "message_ssid",
    name: "Message SSID",
  },
  {
    key: "viewContent",
    displayCell: () => (
      <TemplateButton
        title="View Content"
        color="#00AEEF"
        icon={<InfoButtonIcon />}
        clickHandler={() => alert("view content clicked")}
      />
    ),
  },
];

const emailsColumns = [
  {
    key: "created",
    name: "Created Date",
  },
  {
    key: "status",
    name: "Status",
    displayCell: (status) => <EmailStatusBadge status={status} />,
  },
  {
    key: "recipient_mrn",
    name: "Recipient MRN",
  },
  {
    key: "recipient_email",
    name: "Recipient Email",
  },
];

const notesColumns = [
  {
    key: "id",
    name: "Id",
  },
  {
    key: "user",
    name: "User",
  },
  {
    key: "created",
    name: "Created Date",
  },
  {
    key: "note_title",
    name: "Note Title",
  },
  {
    key: "note_content",
    name: "Note Content",
  },
];

const SaveButton = styled.button`
  width: 129px;
  height: 38px;
  background-color: ${props=> props.disabled? '#00AEEF4D':'#00aeef'};
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
`;

const cohortsColumns = [
    {
        key:'cohort_title',
        name:'Cohort Name'
    },
    {
        key:'description',
        name:'Cohort Description'
    },
]


const StatusesContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
  width:244px;

`

const HeaderLinks = styled.span`
color:#00AEEF;
font-weight:400;
font-size:14px;
font-family:Open Sans;
cursor:pointer;
`


 const HeaderCard = styled.div`
  background: #FFFFFF;
  border:${props => !props.paginated && '1px solid #C2CCD9'} ;
  box-sizing: border-box;
  margin-bottom:26px;
  border-radius:${props => !props.paginated && '8px'};
  padding: 30px;
  height: 265px;
`

const PatientDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mrnValue = searchParams.get("MRN");
  const { id } = useParams();
  const [patientId, setPatientId] = useState();
  const history = useHistory()

  const patientDetails = useSelector(
    (state) => state.radAdmin.patientDetails?.data
  );
  const patientAppointmentRow = useSelector(
    (state) => state.radAdmin.patientDetails.appointments?.data
  );
  const patientReportRow = useSelector(
    (state) => state.radAdmin.patientDetails.reports?.data
  );
  const patientRecosRow = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations?.data
  );
  const notesData = useSelector((state) => state.radAdmin.notes?.data);
  const messages = useSelector(
    (state) => state.radAdmin.patientDetails.messages?.data
  );
  const emails = useSelector(
    (state) => state.radAdmin.patientDetails.emails?.data
  )?.map((itm) => ({
    ...itm,
    recipient_email: (patientDetails && patientDetails?.email) || "N/A",
    recipient_mrn: patientDetails?.mrn || "N/A",
  }));

  const totalMessages = useSelector(
    (state) => state.radAdmin.patientDetails.messages?.totalItems
  );
  const totalEmails = useSelector(
    (state) => state.radAdmin.patientDetails.emails?.totalItems
  );

  const totalCohorts = useSelector(state => state.radAdmin.patientDetails.cohorts?.totalItems);


  const prescriptions = useSelector(
    (state) => state.radAdmin.patientDetails.prescriptions?.data
  )?.map((itm) => ({
    ...itm,
    patient:
      (patientDetails &&
        patientDetails.first_name + " " + patientDetails.last_name) ||
      "N/A",
  }));

  const cohorts = useSelector(state => state.radAdmin.patientDetails.cohorts?.data);


  const isLoadingAppointments = useSelector(
    (state) => state.radAdmin.patientDetails.appointments.isLoading
  );
  const isLoadingPrescriptions = useSelector(
    (state) => state.radAdmin.patientDetails.prescriptions?.isLoading
  );
  const isLoadingReco = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations.isLoading
  );
  const isLoadingMessages = useSelector(
    (state) => state.radAdmin.patientDetails.messages.isLoading
  );
  const isLoadingEmails = useSelector(
    (state) => state.radAdmin.patientDetails.emails.isLoading
  );
  const isLoadingReports = useSelector(
    (state) => state.radAdmin.patientDetails.reports.isLoading
  );
  const isLoadingPatient = useSelector(
    (state) => state.radAdmin.patientDetails.isLoading
  );
  const isLoadingNotes = useSelector(
    (state) => state.radAdmin.notes?.isLoading
  );
  const isLoadingCohorts = useSelector(state => state.radAdmin.patientDetails.cohorts?.isLoading)

  const totalNotes = useSelector((state) => state.radAdmin.notes?.totalItems);
  const totalPrescriptions = useSelector(
    (state) => state.radAdmin.patientDetails.prescriptions?.totalItems
  );
  const totalAppointments = useSelector(
    (state) => state.radAdmin.patientDetails.appointments?.totalItems
  );
  const totalReports = useSelector(
    (state) => state.radAdmin.patientDetails.reports?.totalItems
  );
  const totalRecommendations = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations?.totalItems
  );
 
    
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const updatePermission = rolePermissions?.find(itm=>itm.name ==="Patients")?.permissions.find(itm=>itm.codename === "change_patients")?.is_authorized;
  const addPermission =  rolePermissions?.find(itm=>itm.name ==="Patients")?.permissions.find(itm=>itm.codename === "add_patients")?.is_authorized;

  const [pageForSMS, setPageForSMS] = useState(1);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [openReportDetails, setOpenReportDetails] = useState(false);
  const [openReportIndex, setOpenReportIndex] = useState()

  const [selectedData, setSelectedData] = useState(null);
  const [requiredReco, setRequiredReco] = useState(null);
  const [isSelectedData, setIsSelectedData] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [appointmentsPageNumber, setAppointmentsPageNumber] = useState(1);
  const [reportsPageNumber, setReportsPageNumber] = useState(1);
  const [recommendationsPageNumber, setRecommendationsPageNumber] = useState(1);
  const [prescriptionPageNumber, setPrescriptionPageNumber] = useState(1);
  const [cohortsPageNumber, setCohortsPageNumber] = useState(1)
  const [emailPageNumber, setEmailPageNumber] = useState(1);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedPhoneUnsubcribed, setSelectedPhoneUnsubscribed] = useState();
  const [selectedPhoneClosed, setSelectedPhoneClosed] = useState();
  const [selectedPatientEmail, setSelectedPatientEmail] = useState();
  const [selectedPatientPhoneNumber, setSelectedPatientPhoneNumber] =
    useState();
  const [selectedPatientFirstName, setSelectedPatientFirstName] = useState();
  const [selectedPatientLastName, setSelectedPatientLastName] = useState();

  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const node = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [openedRow, setOpenedRow] = useState();
  const [loading, setLoading] = useState(false);


  const handleClick = (event,id) => {
    setAnchorEl(event.currentTarget);
    setOpenedRow(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedRow()
  };

  const open = Boolean(anchorEl);
  const popupId = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (patientDetails) {
      setSelectedLanguage(
        languageOptions.find(
          (itm) => itm.value === patientDetails?.language_preference
        )
      );
      setSelectedPhoneClosed(
        phoneClosedOptions.find(
          (itm) => itm.value === patientDetails?.phone_closed
        )
      );
      setSelectedPhoneUnsubscribed(
        phoneUnsubscribedOptions.find(
          (itm) => itm.value === patientDetails?.phone_unsubscribed
        )
      );
      setSelectedGender(
        genderOptions.find((itm) => itm.value === patientDetails?.gender)
      );
      setSelectedPatientEmail(patientDetails?.email);
      setSelectedPatientPhoneNumber(patientDetails?.phone_number);
      setSelectedPatientFirstName(patientDetails?.first_name);
      setSelectedPatientLastName(patientDetails?.last_name);
    }
  }, [patientDetails]);

  const notesRows = notesData?.map((note) => {
    return {
      id: note.id,
      user: `${note.user.first_name} ${note.user.last_name}`,
      created: moment(note.created).format("DD-MM-YYYY"),
      note_title: note.title,
      note_content: note.note_text,
    };
  });
  const totalNotesPages = Math.ceil(totalNotes / 10);
  const totalPrescriptionsPages = Math.ceil(totalPrescriptions / 10);
  const totalCohortsPages = Math.ceil(totalCohorts/10);
  const totalAppointmentsPages = Math.ceil(totalAppointments / 10);
  const totalRecommendationsPages = Math.ceil(totalRecommendations / 10);
  const totalReportsPages = Math.ceil(totalReports / 10);


  useEffect(() => {
    if (!id) {
      dispatch(getPatientByMrn(mrnValue))
        .then((res) => setPatientId(res.id))
        .catch((err) => console.log(err));
    }
  }, [dispatch, id, mrnValue]);

  useEffect(() => {
    if (id || patientId) {
      dispatch(getPatientById(id || patientId));
    }
  }, [dispatch, id, patientId]);

  useEffect(() => {
    if (selectedData) {
      setRequiredReco(selectedData);
      setIsSelectedData(true);
    }
  }, [selectedData]);

  useEffect(() => {
    if (!isNull(patientDetails) && patientDetails.phone_number) {
      dispatch(
        getMessagesByPatientPhoneNumber(patientDetails.phone_number, pageForSMS)
      );
    }
  }, [dispatch, patientDetails]);


  useEffect(() => {
    if (id || patientId) {
      dispatch(getAppointmentByPatientId(id || patientId, appointmentsPageNumber));
    }
  }, [appointmentsPageNumber, id, patientId]);

  useEffect(() => {
    if (id || patientId) {
      dispatch(getRecommendationByPatientId(id || patientId, recommendationsPageNumber));
    }
  }, [recommendationsPageNumber, id, patientId]);

  useEffect(() => {
    if (id || patientId) {
      dispatch(getReportByPatientId(id || patientId, reportsPageNumber));
    }
  }, [reportsPageNumber, id, patientId]);

  useEffect(() => {
    if (id || patientId) {
      dispatch(getNotes(parseInt(id || patientId), pageNumber));
    }
  }, [pageNumber, id, patientId]);

  useEffect(() => {
    if (id || patientId) {
      dispatch(
        getPrescriptionByPatientId(
          parseInt(id || patientId),
          prescriptionPageNumber
        )
      );
    }
  }, [prescriptionPageNumber, id, patientId]);

  useEffect(() => {
    if (id || patientId) {
      dispatch(
        getEmailsByPatientId(parseInt(id || patientId), emailPageNumber)
      );
    }
  }, [emailPageNumber, id, patientId]);

  useEffect(() => {
    if(id || patientId){
    dispatch(getCohortsByPatientId(parseInt(id || patientId), cohortsPageNumber))
    }
},[cohortsPageNumber, id, patientId])

  const dialogHandler = () => {
    setSelectedMessage(null);
    setSelectedEmail(null);
  };

  const editModalHandle = () => {
    setSelectedData(null);
    setIsSelectedData(false);
  };

  const LoadMoreMessages = () => {
    window.analytics.track("Patient-Details-Load-More-Messages-Click");
    setPageForSMS(pageForSMS + 1);
    dispatch(
      getMessagesByPatientPhoneNumber(
        patientDetails.phone_number,
        pageForSMS + 1
      )
    );
  };

  const LoadMoreEmails = () => {
    window.analytics.track("Patient-Details-Load-More-Emails-Click");
    dispatch(
      getEmailsByPatientId(parseInt(id || patientId), emailPageNumber + 1)
    );
  };

  const handlePreviousPage = () => {
    window.analytics.track("Patient-Details-Notes-Previous-Click");
    pageNumber > 1 && setPageNumber(pageNumber - 1);
  };

  const handleNextPage = () => {
    window.analytics.track("Patient-Details-Notes-Next-Click");
    totalNotesPages > pageNumber && setPageNumber(pageNumber + 1);
  };

  
  const handlePreviousReportsPage = () => {
    window.analytics.track("Patient-Details-Reports-Previous-Click");
    reportsPageNumber > 1 &&
      setReportsPageNumber(reportsPageNumber - 1);
  };

  const handleNextReportsPage = () => {
    window.analytics.track("Patient-Details-Reports-Next-Click");
    totalReportsPages > reportsPageNumber &&
      setReportsPageNumber(reportsPageNumber + 1);
  };


  
  const handlePreviousRecommendationsPage = () => {
    window.analytics.track("Patient-Details-Recommendations-Previous-Click");
    recommendationsPageNumber > 1 &&
      setRecommendationsPageNumber(recommendationsPageNumber - 1);
  };

  const handleNextRecommendationsPage = () => {
    window.analytics.track("Patient-Details-Recommendations-Next-Click");
    totalRecommendationsPages > recommendationsPageNumber &&
      setRecommendationsPageNumber(recommendationsPageNumber + 1);
  };

  
  const handlePreviousAppointmentsPage = () => {
    window.analytics.track("Patient-Details-Appointments-Previous-Click");
    appointmentsPageNumber > 1 &&
      setAppointmentsPageNumber(appointmentsPageNumber - 1);
  };

  const handleNextAppointmentsPage = () => {
    window.analytics.track("Patient-Details-Appointments-Next-Click");
    totalAppointmentsPages > appointmentsPageNumber &&
      setAppointmentsPageNumber(appointmentsPageNumber + 1);
  };


  const handlePreviousPrescriptionPage = () => {
    window.analytics.track("Patient-Details-Prescriptions-Previous-Click");
    prescriptionPageNumber > 1 &&
      setPrescriptionPageNumber(prescriptionPageNumber - 1);
  };

  const handleNextPrescriptionPage = () => {
    window.analytics.track("Patient-Details-Prescriptions-Next-Click");
    totalPrescriptionsPages > prescriptionPageNumber &&
      setPrescriptionPageNumber(prescriptionPageNumber + 1);
  };

  const handlePreviousCohortsPage = () => {
    window.analytics.track("Patient-Details-Cohorts-Previous-Click");
    cohortsPageNumber > 1 &&
      setCohortsPageNumber(cohortsPageNumber - 1);
  };

  const handleNextCohortsPage = () => {
    window.analytics.track("Patient-Details-Cohorts-Next-Click");
    totalCohortsPages > cohortsPageNumber &&
      setCohortsPageNumber(cohortsPageNumber + 1);
  };

  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setSelectedPatientEmail(inputEmail);
    const isValidEmail = validator.isEmail(inputEmail);
    setIsValid(isValidEmail);
  };

  const handlePatientFirstNameChange = (e) => {
    setSelectedPatientFirstName(e.target.value);
  }

  const handlePatientLastNameChange = (e) => {
    setSelectedPatientLastName(e.target.value);
  }


  const [openNewNote, setOpenNewNote] = useState(false);

  const addNoteClickHandler = () => {
    setOpenNewNote(!openNewNote)
    window.analytics.track('Patient-Details-New-Note-Click');
}

const [currentSection, setCurrentSection] = useState(null);

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block:'center' });
  }
};

useEffect(() => {
  if (currentSection) {
    scrollToSection(currentSection);
    setCurrentSection(null)
  }
}, [currentSection]);


const chevronHandler = (direction) =>{
  let requiredIndex = patientRecosRow.findIndex(
    (reco) => reco.id === selectedData.id
  );
  if(direction === "forward"){
    if (requiredIndex < patientRecosRow.length - 1) {
      setSelectedData(patientRecosRow[requiredIndex + 1])
    
    } else if (patientRecosRow.length <= totalRecommendations) {
      handleNextRecommendationsPage()
    }

  }else{
    if(requiredIndex !==0){
      setSelectedData(patientRecosRow[requiredIndex -1])

    }

  }
}

  return (
    <>
      <Container>
        <div style={{display:'flex', gap:'30px', justifyContent:'center', marginBottom:'27px'}}>
          <HeaderLinks onClick={() => setCurrentSection('patient-info')}>Patient Info</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('reports')}>Reports</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('recommendations')}>Recommendations</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('appointments')}>Appointments</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('prescriptions')}>Prescriptions</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('cohorts')}>Cohorts</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('notes')}>Notes</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('emails')}>Email</HeaderLinks>
          <HeaderLinks onClick={() => setCurrentSection('sms')}>SMS</HeaderLinks>
        </div>
        <Row id="patient-info" style={{ marginBottom: 30 }}>
          <Col sm={12} lg={12} xl={12}>
            <HeaderCard>
            <Heading>Patient Info</Heading>

              <div style={{ display: "flex", gap:'30px', marginBottom:'16px' }}>
                <div
                  style={{ display: "flex", gap:'90px', width:"280px" }}
                >
                  <div>
                    <PatientInfoHeading>MRN</PatientInfoHeading>
                    <PatientInfo>
                      {(patientDetails && patientDetails.mrn) || "N/A"}
                    </PatientInfo>
                  </div>
                  <div>
                    <PatientInfoHeading>DATE OF BIRTH</PatientInfoHeading>
                    <PatientInfo>
                      {(patientDetails && patientDetails.date_of_birth) || "-"}
                    </PatientInfo>
                  </div>
                </div>
                <div style={{display:'flex', gap:'24px'}}>
                <SelectContainer>
                    <PatientInfoHeading>FIRST NAME</PatientInfoHeading>
                    <input
                    type="text"
                    id="email"
                    value={selectedPatientFirstName}
                    onChange={handlePatientFirstNameChange}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #D2D3D7",
                      padding: "9px 12px",
                      height:'38px'
                    }}
                  />
                  
                  </SelectContainer>
                  <SelectContainer>
                    <PatientInfoHeading>LAST NAME</PatientInfoHeading>
                    <input
                    type="text"
                    id="email"
                    value={selectedPatientLastName}
                    onChange={handlePatientLastNameChange}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #D2D3D7",
                      padding: "9px 12px",
                      height:'38px'
                    }}
                  />
                  
                  </SelectContainer>
              
                <SelectContainer>
                  <PatientInfoHeading>EMAIL ADDRESS</PatientInfoHeading>
                  <input
                    type="text"
                    id="email"
                    value={selectedPatientEmail}
                    onChange={handleEmailChange}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #D2D3D7",
                      padding: "9px 12px",
                      height:'38px'
                    }}
                  />
                  {!isValid && selectedPatientEmail && (
                    <p style={{ color: "red" }}>Invalid Email Address</p>
                  )}
                </SelectContainer>
                <SelectContainer>
                  <PatientInfoHeading>UNSUBSCRIBED</PatientInfoHeading>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={phoneUnsubscribedOptions}
                    value={selectedPhoneUnsubcribed}
                    onChange={(value) => {
                      setSelectedPhoneUnsubscribed(value);
                      window.analytics.track(
                        "Patient-Details-Phone-Unsubscribed-Click"
                      );
                    }}
                    styles={{
                      option: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                      }),
                    }}
                  />
                </SelectContainer>
                </div>
             
              </div>
              <div style={{ display: "flex", gap:'30px'}}>
                <div style={{ display: "block", gap: "22px" , width:'280px'}}>
                <SelectContainer>
                  <PatientInfoHeading>LANGUAGE PREFERENCE</PatientInfoHeading>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={languageOptions}
                    value={selectedLanguage}
                    onChange={(value) => {
                      setSelectedLanguage(value);
                      window.analytics.track(
                        "Patient-Details-Language-Preference-Click"
                      );
                    }}
                    styles={{
                      option: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                      }),
                    }}
                  />
                </SelectContainer>
                </div>
                
              <div style={{display:'flex', gap:"24px", flex:1}}>
              
              <SelectContainer>
                  <PatientInfoHeading>GENDER</PatientInfoHeading>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={genderOptions}
                    value={selectedGender}
                    onChange={(value) => {
                      setSelectedGender(value);
                      window.analytics.track("Patient-Details-Gender-Click");
                    }}
                    styles={{
                      option: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                      }),
                    }}
                  />
                </SelectContainer>

                <SelectContainer>
                  <PatientInfoHeading>PHONE NUMBER</PatientInfoHeading>
                  <PhoneInput
                  country={'us'}
                  value={selectedPatientPhoneNumber}
                  onChange={(value) =>{
                    setSelectedPatientPhoneNumber(value)
                  }
                  }
                  onlyCountries={['us']}
                  countryCodeEditable={true}
                  disableDropdown={false}
                  inputStyle={{ height: '38px', width: '250px' }} 
                  containerStyle={{ width: '250px' }} 
                  />
                </SelectContainer>
                 
                <SelectContainer>
                  <PatientInfoHeading>CLOSED</PatientInfoHeading>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={phoneClosedOptions}
                    value={selectedPhoneClosed}
                    onChange={(value) => {
                      setSelectedPhoneClosed(value);
                      window.analytics.track(
                        "Patient-Details-Phone-Closed-Click"
                      );
                    }}
                    styles={{
                      option: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                      }),
                    }}
                  />
                </SelectContainer>
                <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop:'auto',
                  marginLeft:'77px',
                  flex:1
                }}
              >
                <SaveButton
                  disabled={(!isValid && selectedPatientEmail) ? true: false}
                  onClick={() =>{
                    if((permission && updatePermission) || !permission){
                      setLoading(true);
                      dispatch(
                        updatePatientDetails(id || patientId, {
                          selectedLanguage,
                          selectedGender,
                          selectedPhoneClosed,
                          selectedPhoneUnsubcribed,
                          selectedPatientEmail,
                          selectedPatientPhoneNumber,
                          selectedPatientFirstName,
                          selectedPatientLastName
                        })
                      ).then(res=>setLoading(false))
                    }else{
                      dispatch(
                        showAlert('danger', 'Error', `You don't have permission to update patient`)
                      );
                    } 
                  }
                  }
                >
                   {loading? <Spinner animation="border" role="status" size="sm" /> : 'Save'}
                </SaveButton>
              </div>
              </div>
             
              </div>
            
            </HeaderCard>
          </Col>
        </Row>
        <Row id="reports" style={{ marginBottom: 30 }}>
        <Col sm={12} lg={12} xl={12}>
          <TableContainer>
          <Heading>Reports</Heading>
            <Table
              columns={[].concat(reportColumns.slice(0, 3),  {
                key: "id",
                displayCell: (value, rowData,idx) => (
                  <TemplateButton
                    style={{
                      height: "38px",
                      borderRadius: "4px",
                      borderWidth: "2px",
                      padding: "9px 15px",
                      width: "130px",
                      gap: "8px",
                    }}
                    iconContainerStyles={{ padding: "0px" }}
                    textStyles={{
                      fontFamily: "Open Sans",
                      lineHeight: "19px",
                      whiteSpace: "nowrap",
                    }}
                    title="View Details"
                    color="#00AEEF"
                    icon={<InfoButtonIcon />}
                    clickHandler={() => {
                      window.analytics.track(
                        "Patient-Details-Reports-View-Content-Click"
                      );
                      setOpenReportIndex(idx)
                      setOpenReportDetails(true)
                    }}
                  />
                ),
              })}
              rows={patientReportRow && patientReportRow}
              isLoadingRow={isLoadingReports}
              isPaginated={true}
            />
              {!isLoadingReports && patientReportRow?.length ? (
                <PageContainer>
                  <IconContainer
                    paddingRight="7px"
                    onClick={handlePreviousReportsPage}
                  >
                    <PaginationLeftArrow />  Previous
                  </IconContainer>
                  {` ${reportsPageNumber} of ${totalReportsPages} `}
                  <IconContainer
                    
                    paddingLeft="7px"
                    onClick={handleNextReportsPage}
                  >
                  Next  <PaginationRightArrow />
                  </IconContainer>
                </PageContainer>
              ) : (
                <></>
              )}
          </TableContainer>
          </Col>
        </Row>
        <Row id="recommendations" style={{ marginBottom: 30 }}>
          <Col sm={12} lg={12} xl={12}>
            <TableContainer>
            <Heading>Recommendations</Heading>
            <Table
              columns={[].concat(recommendationColumns.slice(0, 6), [
                {
                  key:'conditional',
                  name:'Conditional',
                  displayCell: (cellValue, rowData) => (
                   cellValue? 
                     <ConditionalIcon/>
                   : null
                  ),
                },
                {
                  key: "status",
                  name: "Status",
                  displayCell: (status, row) =>   <>
                <StatusBadge onClick={(e)=>handleClick(e,row.id)} status={status} styles={{marginLeft:0}} />
                  </>  
                },
                {
                  key: "id",
                  displayCell: (value, rowData) => (
                    <TemplateButton
                      style={{
                        height: "38px",
                        borderRadius: "4px",
                        borderWidth: "2px",
                        padding: "9px 15px",
                        width: "130px",
                        gap: "8px",
                      }}
                      iconContainerStyles={{ padding: "0px" }}
                      textStyles={{
                        fontFamily: "Open Sans",
                        lineHeight: "19px",
                        whiteSpace: "nowrap",
                      }}
                      title="View Details"
                      color="#00AEEF"
                      icon={<InfoButtonIcon />}
                      clickHandler={() => {
                        window.analytics.track(
                          "Patient-Details-Recommendations-View-Content-Click"
                        );
                        setSelectedData({
                          ...rowData,
                        });
                      }}
                    />
                  ),
                }
              ])}
              rows={patientRecosRow && patientRecosRow}
              isLoadingRow={isLoadingReco}
              isPaginated={true}
            />
              {!isLoadingReco && patientRecosRow?.length ? (
                <PageContainer>
                  <IconContainer
                    paddingRight="7px"
                    onClick={handlePreviousRecommendationsPage}
                  >
                    <PaginationLeftArrow />  Previous
                  </IconContainer>
                  {` ${recommendationsPageNumber} of ${totalRecommendationsPages} `}
                  <IconContainer
                    
                    paddingLeft="7px"
                    onClick={handleNextRecommendationsPage}
                  >
                   Next <PaginationRightArrow />
                  </IconContainer>
                </PageContainer>
              ) : (
                <></>
              )}
            {
              open &&<Popover
              id={popupId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  borderRadius: '10px', 
                  border: '2px solid #DFE0EB',
                  width:'244px',
                  background: '#F7F8FF',
                  boxShadow:'none',
                  marginTop:'10px'
                },
              }}
            >
               <StatusesContainer ref={node}>
                      <ActionStatusList
                        selectedStatus={selectedStatus}
                        id={openedRow}
                        handleSelectStatus={(data) => {
                          if((permission && updatePermission) || !permission){
                          setSelectedStatus(data.status);
                          dispatch(changeActionStatusActionPatientDetails(data.id, data.status)).then(res=>{
                            dispatch(getRecommendationByPatientId(id || patientId));
                            handleClose();
                          })
                          }else{
                            dispatch(
                              showAlert('danger', 'Error', `You don't have permission to update recommendation status`)
                            );
                          }   
                        }
                      }
                        fromMessage
                      />
                    </StatusesContainer>
                   
            </Popover>
            }
                
            </TableContainer>
          
          </Col>
        </Row>
        <Row id="appointments" style={{ marginBottom: 30 }}>
        <Col sm={12} lg={12} xl={12}>
          <TableContainer>
          <Heading>Appointments</Heading>
            <Table
              columns={appointmentsColumns}
              rows={patientAppointmentRow && patientAppointmentRow}
              isLoadingRow={isLoadingAppointments}
              isPaginated={true}
            />
                {!isLoadingAppointments && patientAppointmentRow?.length ? (
                <PageContainer>
                  <IconContainer
                    paddingRight="7px"
                    onClick={handlePreviousAppointmentsPage}
                  >
                    <PaginationLeftArrow />  Previous
                  </IconContainer>
                  {` ${appointmentsPageNumber} of ${totalAppointmentsPages} `}
                  <IconContainer
                    
                    paddingLeft="7px"
                    onClick={handleNextAppointmentsPage}
                  >
                  Next <PaginationRightArrow />
                  </IconContainer>
                </PageContainer>
              ) : (
                <></>
              )}
          </TableContainer>
          
          </Col>
        </Row>
        <Row  id="prescriptions" style={{ marginBottom: 30 }} >
          <Col sm={12} lg={12} xl={12}>
            <TableContainer>
              <Heading style={{ textAlign: "left" }}>Prescriptions</Heading>
              <Table
                columns={prescriptionsColumns}
                rows={prescriptions && prescriptions}
                isLoadingRow={isLoadingPrescriptions}
                isPaginated={true}
              />
              {!isLoadingPrescriptions && prescriptions?.length ? (
                <PageContainer>
                  <IconContainer
                    paddingRight="7px"
                    onClick={handlePreviousPrescriptionPage}
                  >
                    <PaginationLeftArrow /> Previous
                  </IconContainer>
                  {` ${prescriptionPageNumber} of ${totalPrescriptionsPages} `}
                  <IconContainer
                    
                    paddingLeft="7px"
                    onClick={handleNextPrescriptionPage}
                  >
                    Next <PaginationRightArrow />
                  </IconContainer>
                </PageContainer>
              ) : (
                <></>
              )}
            </TableContainer>
          </Col>
        </Row>
        <Row id="cohorts" style={{ marginBottom: 30 }}>
          <Col sm={12} lg={12} xl={12}>
                        <TableContainer>
                          <ContainerHeader>
                           <Heading style={{marginBottom:'0px'}} align="left">Cohorts</Heading>
                          </ContainerHeader>

                            <Table
                               columns={cohortsColumns.concat([{
                                
                                    key: 'viewContent',
                                    displayCell: (value,row) => (<TemplateButton title="View Cohort" color="#00AEEF" icon={<InfoButtonIcon />} clickHandler={() => {
                                        window.analytics.track('Patient-View-Content-Button-Click');
                                        history.push({
                                            pathname: `/auth/radAdmin/messages/cohorts/patientView/${row.id}`,
                                            state: {row}
                                        })
                                    }}   />)
                            
                                
                               }])}
                               rows={cohorts && cohorts}
                               isLoadingRow={isLoadingCohorts}
                               isPaginated={true}
                            />
                            {(!isLoadingCohorts && cohorts?.length) ?
                            <PageContainer>
                                <IconContainer paddingRight="7px" onClick={handlePreviousCohortsPage}><PaginationLeftArrow /> Previous</IconContainer>
                                {` ${cohortsPageNumber} of ${totalCohortsPages} `}
                                <IconContainer  paddingLeft="7px" onClick={handleNextCohortsPage}> Next <PaginationRightArrow/></IconContainer>
                                </PageContainer>
                            : <></>}
                        </TableContainer>
                    </Col>
        </Row>
        <Row id="notes" style={{ marginBottom: 30 }}>
        <Col sm={12} lg={12} xl={12}>
          <TableContainer>
            <ContainerHeader>
              <Heading style={{marginBottom:'0px'}}>Notes</Heading>
              <SaveButton onClick={()=>{
                 if((permission && addPermission) || !permission){
                  setOpenNewNote(true)
                }else{
                  dispatch(
                    showAlert('danger', 'Error', `You don't have permission to create note`)
                  );
                }       
              }}>New Note</SaveButton>
           </ContainerHeader>
            <Table
                columns={notesColumns}
                rows={notesRows && notesRows}
                isLoadingRow={isLoadingNotes}
                isPaginated={true}
              />
              {!isLoadingNotes && notesRows?.length ? (
                <PageContainer>
                  <IconContainer
                    paddingRight="7px"
                    onClick={handlePreviousPage}
                  >
                    <PaginationLeftArrow />  Previous
                  </IconContainer>
                  {` ${pageNumber} of ${totalNotesPages} `}
                  <IconContainer
                    
                    paddingLeft="7px"
                    onClick={handleNextPage}
                  >
                   Next <PaginationRightArrow />
                  </IconContainer>
                </PageContainer>
              ) : (
                <></>
              )}
            </TableContainer>
          </Col>
        </Row>
        <Row id="emails" style={{ marginBottom: 30 }}>
          <Col sm={12} lg={12} xl={12}>
            <TableContainer>
            <Heading align="left">Email</Heading>
              <Table
                columns={emailsColumns.concat([
                  {
                    key: "viewContent",
                    displayCell: (value, row) => (
                      <TemplateButton
                        title="View Content"
                        color="#00AEEF"
                        width
                        icon={<InfoButtonIcon />}
                        clickHandler={() => {
                          window.analytics.track(
                            "Patient-Details-Email-View-Content-Click"
                          );
                          setSelectedEmail(row);
                        }}
                        style={{
                          height: "38px",
                          borderRadius: "4px",
                          borderWidth: "2px",
                          padding: "9px 13px",
                          width: "144px",
                          gap: "8px",
                        }}
                        iconContainerStyles={{ padding: "0px" }}
                        textStyles={{
                          fontFamily: "Open Sans",
                          lineHeight: "19px",
                          whiteSpace: "nowrap",
                        }}
                      />
                    ),
                  },
                ])}
                rows={emails && emails}
                isLoadingRow={
                  (isLoadingEmails || isLoadingPatient) && isNull(emails)
                }
                isPaginated={true}
              />
            </TableContainer>
            {
              emails?.length>0 &&   <LoadMoreContainer
              disabled={emails && totalEmails === emails.length}
              onClick={
                emails && totalEmails !== emails.length
                  ? () => LoadMoreEmails()
                  : null
              }
              style={{maxWidth:'912px', margin:'0 auto', marginTop:'30px'}}
            >
              {isLoadingEmails ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Load more"
              )}
            </LoadMoreContainer>
            }
          
          </Col>
        </Row>

        <Row id="sms" style={{ marginBottom: 100 }}>
          <Col sm={12} lg={12} xl={12}>
            <TableContainer>
            <Heading align="left">SMS</Heading>
              <Table
                columns={[].concat(
                  messagesColumns.slice(0, 1),
                  [
                    {
                      key: "status",
                      name: "Status",
                      displayCell: (status) => (
                        <MessageStatus status={status}  styles={{marginLeft:0}}/>
                      ),
                    },
                  ],
                  messagesColumns.slice(2, messagesColumns.length - 1),
                  [
                    {
                      key: "viewContent",
                      displayCell: (value, row) => (
                        <TemplateButton
                          title="View Content"
                          color="#00AEEF"
                          width
                          icon={<InfoButtonIcon />}
                          clickHandler={() => {
                            window.analytics.track(
                              "Patient-Details-Messages-View-Content-Click"
                            );
                            setSelectedMessage(row);
                          }}
                          style={{
                            height: "38px",
                            borderRadius: "4px",
                            borderWidth: "2px",
                            padding: "9px 13px",
                            width: "144px",
                            gap: "8px",
                          }}
                          iconContainerStyles={{ padding: "0px" }}
                          textStyles={{
                            fontFamily: "Open Sans",
                            lineHeight: "19px",
                            whiteSpace: "nowrap",
                          }}
                        />
                      ),
                    },
                  ]
                )}
                rows={messages && messages}
                isLoadingRow={
                  (isLoadingMessages || isLoadingPatient) && isNull(messages)
                }
                isPaginated={true}
              />
            </TableContainer>
            {
              messages?.length>0 &&    <LoadMoreContainer
              disabled={messages && totalMessages === messages.length}
              onClick={
                messages && totalMessages !== messages.length
                  ? () => LoadMoreMessages()
                  : null
              }
              style={{maxWidth:'912px', margin:'0 auto',  marginTop:'30px'}}
            >
              {isLoadingMessages ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Load more"
              )}
            </LoadMoreContainer>
            }
          
          </Col>
        </Row>
        {selectedEmail && (
          <ShowMessageContent
            messageContent={selectedEmail.content}
            show={!isNull(selectedEmail)}
            dialogHandler={dialogHandler}
            recipientEmail={selectedEmail.recipient_email}
            sourceEmail={recoConfigData?.name || "-"}
            customHeader="Email Content"
            isEmailType={true}
          />
        )}
        {selectedMessage && (
          <ShowMessageContent
            messageContent={selectedMessage.message}
            show={!isNull(selectedMessage)}
            dialogHandler={dialogHandler}
            patientPhoneNumber={selectedMessage.patient_phone_number}
            twilioPhoneNumber={selectedMessage.twilio_phone_number}
          />
        )}
      </Container>
      {selectedData && isSelectedData && (
        <EditReco
          openDialog={selectedData !== null}
          selectedData={
            {...requiredReco}
          }
          editModalHandle={editModalHandle}
          activeStatus={selectedData.status}
          chevronHandler={chevronHandler}
          fromPatientDetails={true}
        />
      )}
         {openNewNote && <NewNote openDialog={openNewNote} addNoteClickHandler={addNoteClickHandler} selectedData={{patientId: id || patientId}} setNotesPage={setPageNumber} />
                }

        {openReportDetails && (
          <ViewReportModal
            data={patientReportRow[openReportIndex]}
            showModal={openReportDetails}
            closeModal={() => {
              setOpenReportIndex()
              setOpenReportDetails(false)
            }}
            handlePrev={()=>{ 
              if(openReportIndex>0){
                setOpenReportIndex(prev=>prev -1)
              }
            }}
            handleNext={()=>{
              if(openReportIndex === patientReportRow.length -1 && totalReports !== patientReportRow.length){
                handleNextReportsPage()
                setOpenReportIndex(prev=>prev +1)
              }
              else if(openReportIndex<patientReportRow.length -1){
                setOpenReportIndex(prev=>prev +1)
              }
             
          }}
          isPaginated={true}
          disabledPrev={openReportIndex === 0}
          disabledNext={openReportIndex ===patientReportRow.length -1 && !(totalReports !== patientReportRow?.length)}
          isLoading={isLoadingReports}
          />
        )}
    </>
  );
};

export default PatientDetails;
