import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth/actions';
import { getConstants } from '../../actions/constants';
import { DashboardIcon, ClipboardIcon, PinIcon, ClockIcon, FileTextIcon, MessageSquareIcon, CheckListIcon } from './Icons';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../UI/Logo/Logo';
import EnableFeature from '../EnableFeature/EnableFeature';
import SendIcon from '@material-ui/icons/Send';
import TipsAndUpdatesIcon from '@material-ui/icons/EmojiObjects';

const Header = styled.header`
  width: 100%;
  height: ${({ theme }) => theme.header.height}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: ${(props) => (props.transparent ? 'inherit' : 'fixed')};
  top: 0;
  left: 0;
  border-bottom: ${(props) => (props.transparent ? 'transparent' : '1px solid #ced5df')};
  z-index: 10;
  background: ${(props) => (props.transparent ? 'transparent' : '#0D2A44')};
`;

const LogoWrapper = styled.div`
  min-width: ${({ theme }) => theme.sidebar.width}px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.transparent ? 'hidden' : 'inherit')};
`;

const Menu = styled(NavDropdown)`
  margin: 0 30px;

  .dropdown-toggle {
    padding: 0;
  }

  .dropdown-toggle::after {
    display: none;
  }

  svg {
    fill: ${(props) => (props.transparent ? 'white' : 'black')};
  }
`;

const MenuLink = styled(Link)`
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #3f4857 !important;
  text-decoration: none !important;
  white-space: nowrap !important;

  svg {
    margin-right: 5px;
  }
`;

const MobileNavbar = ({ user, transparent }) => {
  const dispatch = useDispatch();
  const featureFlags = useSelector(state => state.constants.featureFlags);
  const isDoctor = user?.role === 'doctor';
  const isRadmin = user?.role === 'radiology_admin';
  const isHaveUploads = user?.isHaveUploads;

  useEffect(() => {
    if(!featureFlags.length) {
      dispatch(getConstants());
    }
  }, []);

  const onClickLogout = useCallback(() => {
    dispatch(logout());
  }, []);

  if(!featureFlags.length) {
    return null;
  }

  return (
    <Header transparent={transparent}>
      <LogoWrapper transparent={transparent}>
        <Logo />
      </LogoWrapper>
      <Menu title={(<MenuIcon style={{fill: "#ffffff"}}/>)} transparent={transparent}>
        { isRadmin  && (
          <>

            <Menu.Item as={MenuLink} to='/auth/radAdmin/Overview'>
              <DashboardIcon /> Overview
            </Menu.Item>
            <EnableFeature featureName={'ref-docs-list-view'}>
              <Menu.Item as={MenuLink} to='/auth/radAdmin/referringDocs/1'>
                <ClipboardIcon /> Quality Metrics
              </Menu.Item>
            </EnableFeature>
            <EnableFeature featureName={'enable-follow-up-list-view'}>
              <Menu.Item as={MenuLink} to='/auth/radAdmin/followUpList'>
                <CheckListIcon /> Follow-Ups List
              </Menu.Item>
            </EnableFeature>
            <EnableFeature featureName={'view-messages'}>
              <Menu.Item as={MenuLink} eventKey='link-7' to='/auth/radAdmin/messages'>
                <MessageSquareIcon /> Messages
              </Menu.Item>
            </EnableFeature>
            <Menu.Item as={MenuLink} to={isHaveUploads ? '/auth/radAdmin/Import' : '/auth/radAdmin/dashboard'}>
              <FileTextIcon /> Import
            </Menu.Item>
            <Menu.Item as={MenuLink} to='/auth/radAdmin/previousUploads'>
              <ClockIcon /> Previous Uploads
            </Menu.Item>

            <Menu.Item as={MenuLink} to={{ pathname: "mailto:support@seewithin.co" }} target="_blank">
              <SendIcon  style={{width:"20px" , height:'20px', color:'#C2C2C2', fill :'#C2C2C2'}} /> Support
            </Menu.Item>
            <Menu.Item as={MenuLink}  to={{ pathname: "https://docs.seewithin.co/docs/getting-started" }} target="_blank" >
              <TipsAndUpdatesIcon  style={{width:"20px" , height:'20px', color:'#C2C2C2', fill :'#C2C2C2'}} /> Docs
            </Menu.Item>
          </>
        )}
        {
           isRadmin &&
           <Menu.Item as={MenuLink} to='/auth/radAdmin/settings'>
             <span style={{marginLeft: '14%'}}>Settings</span>
           </Menu.Item>
        }
        <Menu.Item as={MenuLink} onClick={onClickLogout}>
          <span style={{marginLeft: '14%'}}>Log out</span>
        </Menu.Item>
      </Menu>
    </Header>
  );
};

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user
  };
}

export default connect(mapStateToProps, null)(MobileNavbar);
