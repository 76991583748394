
const initialState = {
    email: [],
    password: [],
};

const apiErrorReducer = (state = initialState, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

export default apiErrorReducer;
