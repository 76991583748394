import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextareaAutosize, Dialog, DialogContent, Divider } from '@material-ui/core';
import { SmsInfoContainer, SmsContent, ParagraphContainer } from './SmsCreationDialog';
import { DialogTitle } from '../../../components/Dialog/NewNote';

import styled from 'styled-components';
import PDFViewer from '../../../components/PDFViewer';

const useStyles = makeStyles(theme => ({
    smsFromSec: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    smsToSec: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: "28px",
        marginBottom: "0px",
        fontWeight: "bold",
        color:'#0C2945',
        fontFamily:'Roboto Condensed'
    },
}));

const Label = styled.p`  
  color:#9FA2B4;
  font-size:12px;
  font-weight:600;
  margin-bottom:0;
`

const LabelValue = styled.p`  
  color:#000000;
  font-size:14px;
  font-weight:400;
  margin-bottom:0;
`

function ShowMessageContent({ messageContent, show, dialogHandler, twilioPhoneNumber, patientPhoneNumber, recipientEmail="", sourceEmail="", customHeader="" , isEmailType = false, recipientFax ="", sourceFax="", isPdf=false, styles={} }) {
    const classes = useStyles();

    return (
        <Dialog
            open={show}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
            fullWidth
            onClose={dialogHandler}
        >
            <DialogTitle id="customized-dialog-title" onClose={() => dialogHandler()}>
                <p className={classes.title}>Message Content</p>
            </DialogTitle>
            <DialogContent style={{minHeight:'300px'}}>
                <Grid style={styles} className={classes.centerSec}>
                    <div style={{display:'flex', gap:'86px', marginBottom:'30px'}}>
                     <div style={{display:'flex', flexDirection:'column', gap:'7px'}}>
                     <Label>From</Label>
                     <LabelValue>{twilioPhoneNumber || sourceEmail || sourceFax}</LabelValue>
                     </div>
                     <div style={{display:'flex', flexDirection:'column', gap:'7px'}}>
                     <Label>To </Label>
                     <LabelValue>
                       {patientPhoneNumber || recipientEmail || recipientFax}
                     </LabelValue>
                     </div>
                    </div>
                     <div style={{marginBottom:'7px'}}>
                     <Label> {customHeader || `SMS Content` } </Label>
                     </div>
                      <div >
                     { isPdf ? (messageContent? <PDFViewer src={messageContent} />: "No available content to display") :  isEmailType ?
                          <div style={{
                              width: "100%",
                              border: "none",
                              fontSize: 14,
                              color: '#000000',
                              fontFamily: 'Open Sans',
                              display: 'inline',
                              backgroundColor: 'white',
                              resize: "none"
                          }}
                          dangerouslySetInnerHTML={{__html: messageContent}} />
                          :
                          <TextareaAutosize
                          maxRows={12}
                          aria-label="maximum height"
                          style={{
                              width: "100%",
                              border: "none",
                              fontSize: 14,
                              color: '#000000',
                              fontFamily: 'Open Sans',
                              display: 'inline',
                              backgroundColor: 'white',
                              resize: "none",
                              padding:0
                          }}
                          disabled
                          defaultValue={messageContent}
                      >
                      </TextareaAutosize> }
                  
                  
                  </div>
                     
                   
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ShowMessageContent
