import React, { useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IntegrationsPanel } from '../styles';
import Box from '../../../../components/_styles/Box';
import Step from './Step';
import Setup from './Setup';
import Flex from '../../../../components/_styles/Flex';
import ReservedAttributes from './ReservedAttributes';
import Confirm from './Confirm';
import styled from 'styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/arrow_left.svg';
import { createRadminUpload, hideAlert } from '../../../../actions/radminUploads';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertContainer } from 'react-bs-notifier';

const STEPS = [
  {order: 1, name: 'Setup'},
  {order: 2, name: 'Reserved Attributes'},
  {order: 3, name: 'Confirm'}
];

const APP_HEADERS = [
  { key: 'PatientReportText', name: 'Report Text', isRequired: true },
  { key: 'PatientMRN', name: 'MRN#', isRequired: true },
  { key: 'AccessionNumber', name: 'AccessionNumber', isRequired: true },
  { key: 'InitialExamDate', name: 'Exam Date', isRequired: true },
  { key: 'PatientPhoneNumber', name: 'Patient Phone Number', isRequired: false },
  { key: 'PatientEmail', name: 'Patient Email address', isRequired: false },
  { key: 'PatientFirstName', name: 'Patient First Name', isRequired: false },
  { key: 'PatientLastName', name: 'Patient Last Name', isRequired: false },
  { key: 'ReferringPhysicianFirstName', name: 'Referring Doc First Name', isRequired: false },
  { key: 'ReferringPhysicianLastName', name: 'Referring Doc Last Name', isRequired: false },
  { key: 'ReadingRadiologistFirstName', name: 'Reading Radiologist First Name', isRequired: false },
  { key: 'ReadingRadiologistLastName', name: 'Reading Radiologist Last Name', isRequired: false }
];

const PATIENTS_HEADER = [
  { key: 'PatientMRN', name: 'MRN#', isRequired: true },
  { key: 'PatientPhoneNumber', name: 'Patient Phone Number', isRequired: false },
  { key: 'PatientEmail', name: 'Patient Email address', isRequired: false },
  { key: 'PatientFirstName', name: 'Patient First Name', isRequired: false },
  { key: 'PatientLastName', name: 'Patient Last Name', isRequired: false },
  { key: 'PatientDOB', name: 'Patient DOB', isRequired: false}
]

const PHYSICIANS_HEADER = [
  {key: 'PhysicianUniqueIdentifier', name: 'Unique Id', isRequired: true},
  {key: 'PhysicianEmail', name: 'Physician Email Address', isRequired: false},
  {key: 'PhysicianFax', name: 'Physician Fax Number', isRequired: false},
  {key: 'PhysicianFirstName', name: 'Physician First Name', isRequired: false},
  {key: 'PhysicianLastName', name: 'Physician Last Name', isRequired: false},
  {key: 'PhysicianPhoneNumber', name: 'Physician Phone Number', isRequired: false},
  {key: 'PhysicianReferrerCommsUnsubscribe', name: 'Referrer Comms Unsubscribe', isRequired: false},
  {key: 'PhysicianReferrerPatientCommsUnsubscribe', name: 'Referrer Patient Comms Unsubscribe', isRequired: false}
]

export const CardTitleContainer = styled(Flex)`
  border-bottom: 3px solid #00AEEF;
`

export const CardTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0C2945;
  padding: 0 60px 20px 30px;
`

const Card = styled.div`
  display: flex;
  font-family: Roboto Condensed;
  font-style: normal;
`
const BackContainer = styled.div`
  align-items: center;
  display: flex;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px
}
`

export const CardHeader = styled(Row)`
  margin-left: -30px !important;
`

export const PreviousPageLink = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3F4857;
  margin-left: 10px;
  margin: 0;
  cursor: pointer;
  padding-left: 10px
`

const Container = styled(Flex)`
  height: 100%;
  padding-top: 20px;
`

const StyledIntegrationsPanel = styled(IntegrationsPanel)`
  height: 100%;
  width: calc(100vw - 240px - 45px - 45px);
  @media (max-width: 768px) {
    width: calc(100vw - 45px - 45px);
  }
`

const PatientImport = ({ history }) => {
  const [currentStepOrder, setCurrentStepOrder] = useState(1);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [userHeadersKeys, setUserHeadersKeys] = useState(null);
  const [previewRows, setPreviewRows] = useState(null);
  const [headersMapping, setHeadersMapping] = useState(null);
  const [uploadType, setUploadType] = useState("Reports");

  const userId = useSelector((state) => state.auth.user.id);
  const isUserHaveUploads = useSelector((state) => state.auth.user.isHaveUploads);
  const alert = useSelector((state) => state.radAdmin.alert);
  const dispatch = useDispatch();

  const moveToNextStep = () => {
    setCurrentStepOrder(currentStepOrder + 2);
    window.analytics.track('CSV-moveToNextStep');
  };

  const moveToBackStep = () => {
    setCurrentStepOrder(currentStepOrder - 2);
    window.analytics.track('CSV-moveToBackStep');
  };

  const cancelImportAll = () => {
    setFile(null);
    setUserHeadersKeys(null);
    setPreviewRows(null);
    setHeadersMapping(null);
    setCurrentStepOrder(1);
    window.analytics.track('Cancel-CSV-Import');
  }

  const cancelImportFile = (name) => {
    if(file.length < 2) {
      cancelImportAll();
      return;
    }
    const restFiles = file.filter(item => item.name !== name);
    const restUserHeadersKeys = userHeadersKeys.filter(item => name !== item.find(itm => typeof itm === 'object').fileName);
    const restPreviewRows = previewRows.filter(item => name !== item.find(itm => typeof itm === 'object').fileName);
    const restHeadersMapping = headersMapping.filter((item => name !== item.fileName));
    setFile(restFiles);

    setUserHeadersKeys(restUserHeadersKeys);
    setPreviewRows(restPreviewRows);
    setHeadersMapping(restHeadersMapping);
  }

  const moveToOverview = () => history.push('/auth/radAdmin/Import');

  const onFinishImport = (e) => {
    /* e.preventDefault(); */
    const importType = uploadType === 'Reports' && 'report' || uploadType === 'Appointments' && 'appointment' || uploadType === 'Patients' && 'patient' || uploadType === 'Physicians' && "physician";
    const blobs = file.map(f => new Blob([f]));
    const formsData = blobs.map(f => {
      const formData = new FormData();
      formData.append('upload_file', f);
      formData.append('user', userId);
      formData.append('entity_type', importType);
      formData.append('perform_updates',true);
      // formData.append('mapping', JSON.stringify(headersMapping));
      return formData;
    });
    dispatch(createRadminUpload(userId, isUserHaveUploads, formsData, cancelImportAll, moveToOverview, importType));
    window.analytics.track('Import-CSV-Submit');
  }

  const onDismiss = useCallback(() => {
    dispatch(hideAlert());
  }, [dispatch]);

  return (
    <Box p={30}>
      <AlertContainer>{alert.active && <Alert {...alert} onDismiss={onDismiss} />}</AlertContainer>
     {currentStepOrder !== 1 && 
      <BackContainer>
        <span style={{cursor: 'pointer'}} onClick={moveToBackStep}><ArrowLeftIcon/></span>
        <PreviousPageLink onClick={moveToBackStep}>Previous Page</PreviousPageLink>
      </BackContainer>
      }
      <Card>
        <Col lg={12} xl={8}>
          <StyledIntegrationsPanel p={30} minHeight={470} minWidth={900}>
            <CardHeader>
              <CardTitleContainer>
                <CardTitle>Import Patient CSV</CardTitle>
              </CardTitleContainer>
              {/* <Flex>
                {STEPS.map((step, i) => (
                  <Step key={i} name={step.name} isCompleted={step.order < currentStepOrder} />
                ))}
              </Flex> */}
            </CardHeader>
            <Container>
              {currentStepOrder === 1 && (
                <Setup
                  file={file}
                  appHeaders={ uploadType ==='Physicians'? PHYSICIANS_HEADER : uploadType === 'Patients' ? PATIENTS_HEADER : APP_HEADERS}
                  setFile={setFile}
                  setUserHeadersKeys={setUserHeadersKeys}
                  setPreviewRows={setPreviewRows}
                  uploadType={uploadType}
                  setUploadType={setUploadType}
                  moveToNextStep={moveToNextStep}
                  setHeadersMapping={setHeadersMapping}
                  data={data}
                  setData={setData}
                  patients={uploadType === 'Patients' ? true : false}
                />
              )}
              {currentStepOrder === 2 && (
                <ReservedAttributes
                  userHeadersKeys={userHeadersKeys}
                  appHeaders={uploadType === 'Patients' ? PATIENTS_HEADER : APP_HEADERS}
                  headersMapping={headersMapping}
                  setHeadersMapping={setHeadersMapping}
                  cancelImportAll={cancelImportAll}
                  cancelImportFile={cancelImportFile}
                  moveToNextStep={moveToNextStep}
                  setUserHeadersKeys={setUserHeadersKeys}
                  files={file}
                  data={data}
                  setData={setData}
                  setPreviewRows={setPreviewRows}
                />
              )}
              {currentStepOrder === 3 && (
                <Confirm
                  previewRows={previewRows}
                  headersMapping={headersMapping}
                  appHeaders={uploadType === 'Patients' ? PATIENTS_HEADER : APP_HEADERS}
                  cancelImportAll={cancelImportAll}
                  cancelImportFile={cancelImportFile}
                  onFinishImport={onFinishImport}
                  files={file}
                />
              )}
            </Container>
          </StyledIntegrationsPanel>
        </Col>
      </Card>
    </Box>
  )
};

export default PatientImport;
